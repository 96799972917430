import { NgModule, ModuleWithProviders } from '@angular/core';
import { WorkorderManagerService } from './workorder-manager.service';
export {
  WorkorderManagerService
}
@NgModule()
export class WorkorderManagerServicesModule {
  static forRoot():ModuleWithProviders{
    return {
      ngModule:WorkorderManagerServicesModule,
      providers:[WorkorderManagerService]
    }
  }
}