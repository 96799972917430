import { Injectable } from '@angular/core';
import { UserInfo } from '../interfaces/UserInfo';
import { StorageModel } from '../model/StorageModel';
@Injectable({
    providedIn: 'root'
})
export class GlobalData {
    public static userInfo:UserInfo
    public static isUpdate:boolean=false;
    public static keyUpdateDate:string
    public static appver:string
    public static nativeAppHeight:string
    public static IsRemoteAssistance:string
    public static paramDate:any
    public static urlParamsModel:any
    public static storageModel=new StorageModel();
    public static allOperatorList = [];
    public static isChangeOperator:boolean=true;
    public static Direction ={
        "2101":"座席有多个外呼任务/座席除外呼任务外还有其它任务",
        "2102":"设置属性消息发送失败",
        "2103":"座席未指定任务号",
        "2110":"座席不存在",
        "2111":"座席暂停使用",
        "2112":"座席密码验证错误",
        "2113":"座席密码未生效",
        "2114":"座席密码已过期",
        "2115":"坐席已锁定",
        "2116":"座席时间戳不匹配",
        "2117":"座席登录分机和绑定分机不匹配",
        "2118":"座席所用分机已被其他座席登录",
        "2119":"座席已登录",
        "2120":"座席不允许被替代",
        "2121":"座席未登录",
        "2122":"座席忙",
        "2123":"座席通话中",
        "2124":"座席后处理中",
        "2125":"坐席未空闲",
        "2126":"座席未接通",
        "2127":"座席未置忙",
        "2128":"永久登录座席必须设置登录（绑定电话）后才可登录 dcp",
        "2130":"对方座席不存在",
        "2131":"对方座席暂停使用",
        "2132":"对方座席已锁定",
        "2133":"对方座席分机未设置",
        "2134":"对方座席未登录",
        "2135":"对方座席忙",
        "2136":"对方座席通话中",
        "2137":"对方座席后处理中",
        "2138":"对方座席空闲",
        "2140":"分机通话中",
        "2141":"分级未设置 IVR 服务",
        "2142":"企业已暂停或无此企业",
        "2143":"坐席登录达到最大限制",
        "2144":"没找到对应的 queuekey"
    }
    
}
