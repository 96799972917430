import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable()
export class SettingsProvider {

  private theme:BehaviorSubject<String>;

  constructor() {
    //默认初始值
    this.theme = new BehaviorSubject('light-theme');
  }

  setActiveTheme(val){
    //新值
    this.theme.next(val);
  }

  getActiveTheme() {
    //观察
    return this.theme.asObservable();
  }
}