import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class WorkorderManagerService {

  private logges: string[] = [];
  private WorkOrderList: any[] = [];
  public eventEmit: any;
  public upDateWorkerOrderEmit: any;
  constructor() {
    this.eventEmit = new EventEmitter();
    this.upDateWorkerOrderEmit = new EventEmitter();
  }

  /**
   * 设置工单数组
   * @param workOrderList 
   */

  setWorkOrderList(workOrderList) {
    this.WorkOrderList = workOrderList;
    this.WorkOrderList.forEach((element) => {
      element["Related"] = true;//生成一个本地ID
    })
  }

  /**
   * 获取工单数组
   * @returns 
   */
  getWorkOrderList() {
    return this.WorkOrderList;
  }

  upDateWorkerOrder(data){
    this.upDateWorkerOrderEmit.emit(data);
  }

  /**
   * 设置单个工单,并返回是否关联
   */
  setWorkOrderModel(workOrderModel, type) {
    if (type == 1 && this.WorkOrderList.filter(
      (item) => (item.PID == workOrderModel.PID && !item.RegCode)).length == 0) {
      workOrderModel["isRelated"] = true;
      this.WorkOrderList.push(workOrderModel);
      this.eventEmit.emit(JSON.stringify({ workOrderModel: workOrderModel, type: type }));
      return true;
    }else if ((type == 2 || type == 4) && this.WorkOrderList.filter(
      (item) => (item.RegCode == workOrderModel.RegCode)).length == 0) {
      workOrderModel["isRelated"] = true;
      this.WorkOrderList.push(workOrderModel);
      this.eventEmit.emit(JSON.stringify({ workOrderModel: workOrderModel, type: type }));
      return true;
    }else if ((type == 3) && this.WorkOrderList.filter(
      (item) => (item.DeptID + item.DocID + item.PlanType == workOrderModel.DeptID + workOrderModel.DocID + workOrderModel.PlanType)).length == 0) {
      workOrderModel["isRelated"] = true;
      this.WorkOrderList.push(workOrderModel);
      this.eventEmit.emit(JSON.stringify({ workOrderModel: workOrderModel, type: type }));
      return true;
    } else if (type == 5 && this.WorkOrderList.filter(
      (item) => (item.HistoryBusID == workOrderModel.HistoryBusID) && item.WorkType == 5).length == 0) {
        workOrderModel["isRelated"] = true;
        this.WorkOrderList.push(workOrderModel);
        this.eventEmit.emit(JSON.stringify({ workOrderModel: workOrderModel, type: type }));
        return true;
    }else {//有，就直接删了
      if (type == 1) this.WorkOrderList = this.WorkOrderList.filter((item) => (item.PID != workOrderModel.PID || item.RegCode));
      else if (type == 2 || type == 4) this.WorkOrderList = this.WorkOrderList.filter((item) => (item.RegCode != workOrderModel.RegCode));
      else if (type == 3) this.WorkOrderList = this.WorkOrderList.filter((item) => (item.DeptID + item.DocID + item.PlanType != workOrderModel.DeptID + workOrderModel.DocID + workOrderModel.PlanType));
      else if (type == 5) this.WorkOrderList = this.WorkOrderList.filter( item => item.HistoryBusID != workOrderModel.HistoryBusID || item.WorkType != 5);
      this.eventEmit.emit(JSON.stringify({ workOrderModel: workOrderModel, type: type, isRelated: 0 }));
      
      return false;
    }

  }

  /**
   * 
   * @param log 
   */

  opraWith

  addLog(log: string) {
    this.logges.push(log);
  }
  getLogges() {
    return this.logges;
  }

  guid() {
    return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }


}
