import { HttpErrorResponse } from "@angular/common/http"
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ChangeDetectorRef,
  SimpleChanges, OnChanges
} from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { AlertController, NavController } from "@ionic/angular"

import { BasePage } from "src/app/common/basepage"
import { BindPatientModel } from "src/app/model/BindPatientModel"
import { RemarkItemsModel } from "src/app/model/RemarkItemsModel"
import { ServiceUserInfoModel } from "src/app/model/ServiceUserInfoModel"
import { Helper } from "src/app/providers/Helper"
import { HttpService } from "src/app/providers/HttpService"
import { StorageManage } from "src/app/providers/StorageManage"
import { Utils } from "src/app/providers/Utils"
import { SearchSelectComponent } from "../search-select/search-select.component"
import * as pinyin from "pinyin"
import { SelectDateComponent } from "src/app/module/select-date/select-date.component"
import { RelateOrderModel } from "src/app/model/RemarkItemsModel"
import { DomSanitizer } from "@angular/platform-browser"
import { WorkorderManagerService } from "src/app/common/workorder-manager.module"
import { GlobalData } from "src/app/providers/GlobalData"
declare var $: any
@Component({
  selector: "app-call-service",
  templateUrl: "./call-service.component.html",
  styleUrls: ["./call-service.component.scss"],
})
export class CallServiceComponent extends BasePage implements OnChanges{
  @ViewChild(SelectDateComponent) selDatePage: SelectDateComponent

  showCloudsImageRefundPopView: boolean = false
  // 是否展示改页面
  isShowHtml: boolean = true
  type: string = ""
  cloudsImageRefundReason: string = ""
  // 1问题类别
  // 2问题分类
  // 3问诊方式
  // 4所属系统
  // 5所属医院
  @Input()
  userPhone: string
  @Input()
  BusID: string
  @Input()
  CSID: string
  @Input()
  currentSelectPatientModel: any
  @Input()
  isSevriceRemark: any
  @Input()
  isHistoryService: boolean = false;//是否是历史服务
  @Input()
  customerServiceMesage: any;

  //投诉人
  IDCardInfoList: any[] = []
  //账号信息
  CardInfo: any

  @Output() getBindPatientList: EventEmitter<any> = new EventEmitter()

  @ViewChild(SearchSelectComponent) SearchSelect: SearchSelectComponent
  @ViewChild("myInput") myInput

  editRemark: string = ""
  bindPatientList: BindPatientModel[] = []
  service400HistoryList: any[] = []
  serviceUserInfo: ServiceUserInfoModel = new ServiceUserInfoModel()
  hasBindPatientInfo: boolean = false
  hasHistory: boolean = false
  isClosed: boolean = false
  isPopup: boolean = false
  remarksHospital: string = ""
  remarkCategory: string = ""
  remarkType: string = ""
  consultType: string = ""
  phoneSource: string = ""
  solution: string = ""
  remark: string = ""
  addTime: string = ""
  PatientName: string = ""; // 投诉患者姓名
  // ConsultPatientName: string = ""; // 咨询患者姓名
  ContactNumber: string = "";
  // ConsultContactNumber: string = "";
  PersonnelRelation: RemarkItemsModel = new RemarkItemsModel();
  isShowRelation: boolean = false
  // 外呼工单需要其他联系人，其他联系人手机号
  otherContacter: string = ""
  otherContactNumber: string = ""
  ishistoryPopup: boolean = false
  historyInfoRow: any = new Object()
  // 是否显示业务类型列表
  isShowServiceList: boolean = false
  // 历史服务详情
  remarksHospitalItems: any[] = []
  // 患者需求
  patientNeedsItems: RemarkItemsModel[] = []
  // 投诉部门
  complaintsDept: RemarkItemsModel[] = []
  // 投诉部门
  QuesCategory: RemarkItemsModel[] = []

  // 投诉部门相关问题（一级父级菜单）
  QuesCategoryParent: any[] = []
  // 投诉部门相关问题（二级子菜单）
  QuesCategoryChildren: any[] = []
  // 投诉分类 （三级子菜单）
  QuesCategoryGrandchild: any[] = []
  // 选中一级菜单
  itemList: any[] = []
  // 选中一级菜单回显标题
  itemTitle: string
  itemTitleArr: any[] = []
  // 选中二级菜单回显标题
  secondTitle: string
  secondTitleArr: any[] = []
  // 页面显示二级菜单
  secondList: any[] = []
  // 存放最后选中二级数据
  secondEndData: any[] = []
  // 选中三级菜单回显标题
  thirdTitle: string
  thirdTitleArr: any[] = []
  // 页面显示三级菜单
  thirdList: any[] = []
  // 存放最后选中的三级数据
  thirdEndData: any[] = []
  // arrList
  arrList: any[] = []
  // 所有144投诉部门数据
  allQuesCategory: any[] = []
  serchName: string
  serchSecondName: string
  serchThirdName: string
  searchList: any[] = []
  serviceCode: any = ""; // 1预约挂号2	取消预约3	咨询4	其他 5:投诉业务工单 6:外呼工单 7：门诊导诊
  fuzzySearchListFiex: any[] = []
  fuzzySearchSecondList: any[] = []
  fuzzySearchThirdList: any[] = []
  // 选择的患者需求
  patientNeedsItem: RemarkItemsModel = new RemarkItemsModel()
  // 投诉部门arr--页面展示的
  addcomplaintsDept = []
  textareaClass: string = ""
  RecordCategory: any
  RecordSeekMedical: any
  RecordStatus: any
  RecordOS: any
  RecordType: any
  RecordHospital: any
  // 问题描述
  recordDiscription: string = ""
  // 解决方案
  recordSolution: string = ""
  // 其他
  other: string = ""
  // 处理状态未：未完成0-进行中2-待处理3-已转办4-待确认5-已完成6-作废-1
  cutDisposeFlag: any = 0
  // 责任人 被投诉人
  duty: string = null
  // 投诉人
  complaint: string = null
  // 医卡通余额
  balance: string = null
  // dept111: string = "角膜病科"
  iselectChange: boolean = true
  isShow: boolean = false
  isShow1: boolean = false
  isShow2: boolean = false
  // 问题描述
  quickInputItems: any[] = [];
  // 解决方案
  solutionInputItems: any[] = []
  // 投诉者 与患者关系
  patientRelationship: any[] = []
  // 科室负责人
  deptLeader: any[] = []
  // 患者就医详情 的搜索条件
  // 查询的身份证号
  IDCardNumber: string = ""
  // 查询的门诊号
  OutPatientRegCode: string = ""
  // 查询的日期
  selectDate: string = ""

  endDate: string = "";

  // 返回的患者信息
  patientInfo: any = null
  // 返回的患者挂号信息。
  RegList: any[] = []
  // 是否显示日期选择器
  isShowDateChooser: boolean = false
  // 是否显示 客服负责人选择器
  isShowDeptManager: boolean = false
  // 是否显示科室列表
  isShowDeptList: boolean = false
  //   当前日期
  Date: string = ""
  // 是否显示查询结果
  isShowResult: boolean = false
  // 查询结果分页
  queryPageIndex: number = 1
  queryPageSize: number = 3
  queryTotal: number = 0
  // 关联工单数组
  WorkOrderList: RelateOrderModel[] = []
  // 用于显示的关联工单类型。
  relateOrderList: any[] = []
  // 当前已完成 工单的数据。
  currentWorkOrderInfo: any = null
  //转办 科室负责人model
  deptManagerModel: RemarkItemsModel = null
  // 三级分类
  EvalTypeList: any[] = []

  isCurrent: boolean = true;//是否是当前工单
  currentPatientModel: any;//当前工单选择患者
  historyPatientModel: any;
  historyWorkOrderLsit: any = new Object();//历史关联工单
  isShowAppointSystem: boolean = false;
  appointSystemURL: any;
  constructor(
    public nav: NavController,
    private sanitizer: DomSanitizer,
    public httpService: HttpService,
    public alertCtr: AlertController,
    public re: ChangeDetectorRef,
    public utils: Utils,
    public storage: StorageManage,
    public helper: Helper,
    public route: ActivatedRoute,
    private workorderManagerService: WorkorderManagerService,
  ) {
    super(utils, nav, route)
  }

  _ngOnInit() {
    this.currentPatientModel = JSON.parse(JSON.stringify(this.currentSelectPatientModel))
    console.log("来电初始化===", this.currentSelectPatientModel)
    //获取400备注选项
    this.getRemarksHospital();
    this.workorderManagerService.upDateWorkerOrderEmit.subscribe((result) => {
      result && result.first == 1 && this.UpdateBusinessStats(true); // 暂存表单数据
      
    })
    this.workorderManagerService.eventEmit.subscribe((result) => {
      let workOrderParam = JSON.parse(result);
      if (workOrderParam.isRelated == '0') {
        this.relateOrder(workOrderParam.type, workOrderParam.workOrderModel, true, null, true);
      } else if (workOrderParam.workOrderModel) {
        this.relateOrder(workOrderParam.type, workOrderParam.workOrderModel, true);
      }

      if (workOrderParam.type == 1) {
        if (this.workorderManagerService.getWorkOrderList().filter(element => element.PID == this.patientInfo.PID && !element.RegCode).length == 0) {//没有，新增
          this.patientInfo['isRelated'] = false;
        } else {//已有
          this.patientInfo['isRelated'] = true;
        }
      } else {
        this.RegList.map(sourceItem => {
          sourceItem['isRelated'] = this.workorderManagerService.getWorkOrderList().some(targetItem => Object.is(targetItem.RegCode, sourceItem.RegCode));
        })
      }
    });
  }

  ngOnDestroy(): void {
    this.UpdateBusinessStats(true); // 暂存表单数据
  }
  configServiceInfo() {
    this.queryOrderInfo();
  }

  /**
   * 获取模块的快捷输入
   */
  GetQuickInputByCSID(type) {
    this.httpService
      .toInteligentServicePost("api/CallCenter/GetQuickInputByCSID", {
        CSID: this.CSID,
        QuickType: type, // 1:问题描述 2: 解决方案
        QuickBussType: this.serviceCode,
        // MSID: this.currentSelectPatientModel.MSID ? this.currentSelectPatientModel.MSID : "",
        PageIndex: 1,
        PageSize: 999,
      })
      .subscribe(
        (result) => {
          if (result.errorcode == "00000000") {
            if (type == 1) {
              this.quickInputItems.length = 0;
              this.quickInputItems = result.data.List.map(item => {
                return item.QuickContent
              });
            } else {
              this.solutionInputItems.length = 0;
              this.solutionInputItems = result.data.List.map(item => {
                return item.QuickContent
              });
            }
          } else {
            console.log("快捷输入结果：+" + result);
          }
        },
        (error: HttpErrorResponse) => {
          console.log(error)
        }
      )
  }

  /**
   * 获取工单详情
   */
  queryOrderInfo() {
    this.helper.showLoading();
    this.patientNeedsItem = null;
    this.httpService
      .toInteligentServicePost("api/CallCenter/GetBusinessCallCenterByBusID", {
        BusID: this.currentSelectPatientModel.BusID,
      })
      .subscribe((result) => {
        if (result.errorcode == "00000000") {
          this.helper.hideLoading();
          this.currentWorkOrderInfo = result.data;
          this.ConsultDept = this.currentWorkOrderInfo.InvolveDept;
          this.serviceCode = this.currentWorkOrderInfo.QuesClassify
          let audio = document.getElementById('work-order-recording') as any;
          if (audio !== null) {
            audio.src = this.currentWorkOrderInfo.Recording;
          }
          var serviceModel = new RemarkItemsModel()
          serviceModel.Code = this.currentWorkOrderInfo.QuesClassify
          serviceModel.Meaning = this.currentWorkOrderInfo.QuesClassifyMeaning
          this.patientNeedsItem = serviceModel;

          this.WorkOrderList = this.currentWorkOrderInfo.WorkOrderList ? this.currentWorkOrderInfo.WorkOrderList : [];
          this.workorderManagerService.setWorkOrderList(this.WorkOrderList);

          this.relateOrderList = this.WorkOrderList;

          //如果绑定的互联网工单不为空，则把此类型工单传出去
          // if(this.WorkOrderList.filter((item)=>item.WorkType==3).length!=0) 
          this.getBindPatientList.emit({ bindOrderList: this.WorkOrderList.filter((item) => item.WorkType == 3) });

          this.complaint = this.currentWorkOrderInfo.EvalName
            ? this.currentWorkOrderInfo.EvalName
            : ""
          this.PatientName = this.currentWorkOrderInfo.PatientName
            ? this.currentWorkOrderInfo.PatientName
            : ""
          this.ContactNumber = this.currentWorkOrderInfo.ContactNumber
            ? this.currentWorkOrderInfo.ContactNumber
            : this.currentSelectPatientModel.Phone
          this.duty = this.currentWorkOrderInfo.InvolvePerson
            ? this.currentWorkOrderInfo.InvolvePerson
            : ""
          this.recordDiscription = this.currentWorkOrderInfo.Remark != null
            ? this.currentWorkOrderInfo.Remark
            : ""
          this.recordSolution = this.currentWorkOrderInfo.Solution
            ? this.currentWorkOrderInfo.Solution
            : ""
          var model = new RemarkItemsModel()
          model.Code = this.currentWorkOrderInfo.PersonnelRelation
          model.Meaning = this.currentWorkOrderInfo.PersonRelationMeaning
          this.PersonnelRelation = model

          if (this.currentWorkOrderInfo.ComplaintsJsonStr) {
            this.addcomplaintsDept = this.currentWorkOrderInfo.ComplaintsJsonStr.length > 0 ? this.currentWorkOrderInfo.ComplaintsJsonStr : [{ "InvolveDept": "搜索部门", "InvolvePerson": "", "DocCID": "" }];
          } else {
            this.addcomplaintsDept = [{ "InvolveDept": "搜索部门", "InvolvePerson": "", "DocCID": "" }];
          }
          // this.addcomplaintsDept = [this.currentWorkOrderInfo.InvolveDept]

          var deptManagerModel = new RemarkItemsModel()
          deptManagerModel.Remark = this.currentWorkOrderInfo.DocCID
          deptManagerModel.Meaning = this.currentWorkOrderInfo.DocName
          this.deptManagerModel = deptManagerModel
          //修改状态

          setTimeout(() => {
            var statusObj = null
            switch (Number(this.currentWorkOrderInfo.IsClose)) {
              case -1:
                statusObj = $(".invalid")
                break
              case 0:
                statusObj = $(".unComplete")
                break
              case 1:
                statusObj = $(".completed")
                break
              case 6:
                statusObj = $(".completed")
                break;
              case 4:
                statusObj = $(".transport")
                break
              default:
                statusObj = $(".unComplete")
                break
            }
            if (statusObj) {
              statusObj.prop("checked", true)
              statusObj.siblings("input").prop("checked", false)
            }
          }, 100);

          this.cutDisposeFlag = this.currentWorkOrderInfo.IsClose
          this.allSetdatas()
          // 三级分类回显
          if (this.currentWorkOrderInfo.EvalTypeList) {
            this.currentWorkOrderInfo.EvalTypeList.forEach((element) => {
              if (element.ThreeLevelName) {
                var model3 = { Meaning: "", Code: "", ParentCode: "" }
                model3.Meaning = element.ThreeLevelName
                model3.Code = element.ThreeLevelCode
                model3.ParentCode = element.TwoLevelCode
                this.thirdEndData.push(model3)
                this.thirdTitleArr.push(model3.Meaning)

                var model2 = { Meaning: "", Code: "", ParentCode: "" }
                model2.Code = element.TwoLevelCode
                model2.Meaning = element.TwoLevelName
                model2.ParentCode = element.OneLevelCode

                let isExist2 = this.secondEndData.some(
                  (ele) => ele.Code === model2.Code
                )
                if (!isExist2) {
                  this.secondEndData.push(model2)
                  this.secondTitleArr.push(model2.Meaning)
                }

                var model1 = { Meaning: "", Code: "", ParentCode: "" }
                model1.Code = element.OneLevelCode
                model1.Meaning = element.OneLevelName

                let isExist1 = this.itemList.some(
                  (ele) => ele.Code === model1.Code
                )
                if (!isExist1) {
                  this.itemList.push(model1)
                  this.itemTitleArr.push(model1.Meaning)
                }
              } else if (element.TwoLevelName && !element.ThreeLevelName) {
                var model2 = { Meaning: "", Code: "", ParentCode: "" }
                model2.Code = element.TwoLevelCode
                model2.Meaning = element.TwoLevelName
                model2.ParentCode = element.OneLevelCode

                let isExist2 = this.secondEndData.some(
                  (ele) => ele.Code === model2.Code
                )
                if (!isExist2) {
                  this.secondEndData.push(model2)
                  this.secondTitleArr.push(model2.Meaning)
                }

                var model1 = { Meaning: "", Code: "", ParentCode: "" }
                model1.Code = element.OneLevelCode
                model1.Meaning = element.OneLevelName

                let isExist1 = this.itemList.some(
                  (ele) => ele.Code === model1.Code
                )
                if (!isExist1) {
                  this.itemList.push(model1)
                  this.itemTitleArr.push(model1.Meaning)
                }
              } else if (
                element.OneLevelName &&
                !element.TwoLevelName &&
                !element.ThreeLevelName
              ) {
                var model1 = { Meaning: "", Code: "", ParentCode: "" }
                model1.Code = element.OneLevelCode
                model1.Meaning = element.OneLevelName
                let isExist1 = this.itemList.some(
                  (ele) => ele.Code === model1.Code
                )
                if (!isExist1) {
                  this.itemList.push(model1)
                  this.itemTitleArr.push(model1.Meaning)
                }
              }
            })
          }

          if (this.itemTitleArr.length > 0) {
            //一级 将回显数据转换成字符串
            this.itemTitleArr.filter((item) => item.Meaning != undefined)
            this.itemTitle = this.itemTitleArr.toString()
          } else {
            this.itemTitle = ""
          }
          if (this.secondTitleArr.length > 0) {
            //二级 将回显数据转换成字符串
            this.secondTitleArr.filter((item) => item.Meaning != undefined)
            this.secondTitle = this.secondTitleArr.toString()
          } else {
            this.secondTitle = ""
          }

          if (this.thirdTitleArr.length > 0) {
            //三级 将回显数据转换成字符串
            this.thirdTitleArr.filter((item) => item.Meaning != undefined)
            this.thirdTitle = this.thirdTitleArr.toString()
          } else {
            this.thirdTitle = ""
          }
        } else {
          console.log(
            "GetBusinessCallCenterByBusID-result:" + JSON.stringify(result)
          )
        }
        this.GetQuickInputByCSID(1);
        this.GetQuickInputByCSID(2);
      })
  }

  ionViewWillEnter() { }
  /**
   * 
   * @param e 1:一级分类 2：二级分类 3：三级分类 4：投诉部门 5：患者关系 6: 科室负责人
   */
  isShowbtn(e) {
    $(".action").off("click", null)
    $(".section").removeAttr("action"); // 投诉部门
    this.isShowDeptList = false;
    this.isShowRelation = false;// 患者关系
    this.isShowDeptManager = false;// 科室负责人
    this.isShowServiceList = false;// 服务类型
    if (e == 1) {
      this.isShow = false
      // $(".action").off("click", null)
      // $(".section").removeAttr("action")
      this.serchName = ""
    } else if (e == 2) {
      this.isShow1 = false
      // $(".action").off("click", null)
      // $(".section").removeAttr("action")
      this.serchSecondName = ""
    } else if (e == 3) {
      this.isShow2 = false
      this.serchThirdName = ""
    } else if (e == 4) {
      this.isShowDeptList = false;
    }
  }
  // 为了阻止事件点击冒泡，给input添加的事件
  inputBtn(e) {
    e.stopPropagation()
  }
  // 切换工单业务类型
  switchBussinessType(item, e = null) {
    if (this.isShowServiceList && item) {
      this.patientNeedsItem = item
      this.serviceCode = item.Code
    }
    this.GetQuickInputByCSID(1);
    this.GetQuickInputByCSID(2);
    this.isShowServiceList = !this.isShowServiceList;
    if (this.isShowServiceList) {
      this.addEventClass(6);
      e && e.stopPropagation();
    }
  }
  // 切换投诉人与患者关系
  switchRelationship(item, e = null) {
    if (this.isShowRelation) {
      if (item) {
        this.PersonnelRelation = item
      }
    }
    this.isShowRelation = !this.isShowRelation;
    if (this.isShowRelation) {
      this.addEventClass(5);
      e && e.stopPropagation();
    }
  }

  currentDeptIndex = 0;
  ConsultDept = '';
  // 选择咨询部门及临床科室
  switchConsulthDept(deptItem, e = null) {
    if (deptItem) this.ConsultDept = deptItem.Meaning
    this.isShowDeptList = !this.isShowDeptList;
    if (this.isShowDeptList) {
      this.addEventClass(4);
      e && e.stopPropagation();
    }
  }
  // 选择投诉部门
  switchDept(index, item, deptItem, e = null) {
    console.log("----------", index);
    if (this.isShowDeptList) {
      if (item) {
        // this.addcomplaintsDept[0] = item.Meaning
        deptItem.InvolveDept = item.Meaning;
      }
    }
    if (index != null) {
      this.currentDeptIndex = index;
    }
    this.isShowDeptList = !this.isShowDeptList;
    if (this.isShowDeptList) {
      this.addEventClass(4);
      e && e.stopPropagation();
    }
  }
  // 选择科室负责人
  switchDeptManager(index, item, deptItem, event = null) {
    console.log("----------", index);
    if (this.isShowDeptManager) {
      if (item) {
        deptItem["DocName"] = item.Meaning;
        deptItem.DocCID = item.Remark;
      }
    }

    if (index != null) {
      this.currentDeptIndex = index;
    }
    console.log(this.deptLeader);
    this.isShowDeptManager = !this.isShowDeptManager
    if (this.isShowDeptManager) {
      this.addEventClass(6);
      event && event.stopPropagation();
    }
  }

  addComplaintDept(type, inedx) {
    if (type == 1) {//加
      this.addcomplaintsDept.push({ "InvolveDept": "搜索部门", "InvolvePerson": "", "DocCID": "" });
    } else {//减
      this.addcomplaintsDept.splice(inedx, 1);
    }
  }

  //所有数据
  allSetdatas() {
    if (localStorage.getItem("arrList")) {
      var arrList = JSON.parse(localStorage.getItem("arrList"))
      this.allQuesCategory = [...arrList]
    } else {
      this.allQuesCategory = [...this.arrList]
    }
  }
  // 快捷输入
  quickInputItemTapped(item) {
    console.log("点击快捷输入" + item)
    // console.log("~~~~~~~~~~" + $(".quickInputItem").text)
    if (1123 == item) {
      $(".quickInputItem").css("background-color", "blue")
      $(".quickInputItem").css("color", "white")
    }
    this.recordDiscription = this.recordDiscription ? this.recordDiscription : "";
    if (!this.recordDiscription.includes(item)) {
      this.recordDiscription += `${item};`;
    }
  }
  solutionItemTapped(item) {
    this.recordSolution = this.recordSolution ? this.recordSolution : "";
    if (!this.recordSolution.includes(item)) {
      this.recordSolution += `${item};`;
    }
  }
  onquestiontextareafocus() {
    this.textareaClass = "questiontextarea"
    $(".questiontextarea").css("border", "1px lightblue solid")
    $(".questiontextarea").css("background-color", "white")
    $(".solutiontextarea").css("border", "1px lightgray solid")
  }
  onsolutiontextareafocus() {
    this.textareaClass = "solutiontextarea"
    $(".solutiontextarea").css("border", "1px lightblue solid")
    $(".solutiontextarea").css("background-color", "white")
    $(".questiontextarea").css("border", "1px lightgray solid")
  }

  changeSolutionContent(solution: string) {
    this.recordSolution += solution;
  }

  // 动态给元素添加属性和事件，解决事件冒泡对searchableSelect的影响
  addEventClass(e) {
    $(".section").addClass("action")
    $(".action").click(($event) => {
      $event.stopPropagation()
      this.isShowbtn(e)
    })
  }

  onchange(e) {
    this.addEventClass(1)
    e.stopPropagation()
    this.isShow = true
    if (this.isShow) {
      this.isShow1 = false
    }
    this.allSetdatas()
    // 将一级数据筛选出来
    var arrFirstList = []
    if (this.allQuesCategory.length > 0) {
      this.allQuesCategory.filter((item) => {
        if (item.ParentCode == null) {
          arrFirstList.push(item)
        }
      })
    }
    // 一级选中状态显示
    if (this.itemList.length > 0 && arrFirstList.length > 0) {
      this.itemList.forEach((item) => {
        arrFirstList.forEach((item2) => {
          if (item.Code == item2.Code) {
            item2.isChecked = true
          }
        })
      })
    }
    this.QuesCategoryParent = arrFirstList
    this.getFrist(this.QuesCategoryParent, 1)
    // console.log('一级数据',this.QuesCategoryParent)
  }
  onchange1(e) {
    this.addEventClass(2)
    e.stopPropagation()
    this.isShow1 = true
    if (this.isShow1) {
      this.isShow = false
    }
    // 二级数据
    var arrSecondList = []
    if (this.itemList.length > 0) {
      this.itemList.forEach((item) => {
        this.allQuesCategory.filter((element) => {
          if (element.ParentCode == item.Code) {
            arrSecondList.push(element)
          }
        })
      })
    }
    // 二级选中状态显示
    if (this.secondEndData.length > 0 && arrSecondList.length > 0) {
      this.secondEndData.forEach((item) => {
        arrSecondList.forEach((item2) => {
          if (item.Code == item2.Code) {
            item2.isChecked = true
          }
        })
      })
    }
    // console.log('二级数据',arrSecondList,this.secondEndData)
    this.secondList = arrSecondList
    this.getFrist(this.secondList, 2)
  }
  onchange2(e) {
    this.addEventClass(3)
    e.stopPropagation()
    this.isShow2 = true
    if (this.isShow2) {
      this.isShow1 = false
    }
    // 三级数据
    var arrThirdList = []
    if (this.secondEndData.length > 0) {
      this.secondEndData.forEach((item) => {
        this.allQuesCategory.filter((element) => {
          if (element.ParentCode == item.Code) {
            arrThirdList.push(element)
          }
        })
      })
    }
    // 三级选中状态显示
    if (this.thirdEndData.length > 0 && arrThirdList.length > 0) {
      this.secondEndData.forEach((item) => {
        arrThirdList.forEach((item2) => {
          if (item.Code == item2.Code) {
            item2.isChecked = true
          }
        })
      })
    }
    this.thirdList = arrThirdList
    this.getFrist(this.thirdList, 3)
  }
  // 输入框的监听
  onChange(e, num) {
    if (num == 1) {
      this.serchName = e.detail.value
      this.getKeyList(num)
    } else if (num == 2) {
      this.serchSecondName = e.detail.value
      this.getKeyList(num)
    } else if (num == 3) {
      this.serchThirdName = e.detail.value
      this.getKeyList(num)
    }
  }
  //添加
  insertData(item, index, e) {
    e.stopPropagation()
    this.isShow = false;
    this.isShow1 = false;
    this.isShow2 = false;
    if (index == 1) {
      //一级菜单
      setTimeout(() => {
        if (item.isChecked == true) {
          //选中
          this.itemTitleArr.push(item.Meaning)
          this.itemList.push(item)
          this.noRepeat2(this.itemList)
        } else {
          //取消
          this.itemList.filter((item, index) => {
            if (item.isChecked == false) {
              this.itemList.splice(index, 1)
            }
          })
          this.itemTitleArr.filter((item1, index) => {
            if (item.Meaning == item1) {
              this.itemTitleArr.splice(index, 1)
            }
          })
        }
        if (this.itemTitleArr.length > 0) {
          //将回显数据转换成字符串
          this.itemTitleArr.filter((item) => item.Meaning != undefined)
          this.itemTitle = this.itemTitleArr.toString()
        } else {
          this.itemTitle = ""
        }
        // console.log(1110,this.itemTitle,this.itemTitleArr,this.itemList)
      })
    } else if (index == 2) {
      //二级菜单
      setTimeout(() => {
        if (item.isChecked == true) {
          //选中
          this.secondTitleArr.push(item.Meaning)
          this.secondEndData.push(item)
          this.noRepeat2(this.secondEndData)
        } else {
          //取消
          this.secondEndData.filter((item, index) => {
            if (item.isChecked == false) {
              this.secondEndData.splice(index, 1)
            }
          })
          this.secondTitleArr.filter((item1, index) => {
            if (item.Meaning == item1) {
              this.secondTitleArr.splice(index, 1)
            }
          })
        }

        if (this.secondTitleArr.length > 0) {
          //将回显数据转换成字符串
          this.secondTitleArr.filter((item) => item.Meaning != undefined)
          this.secondTitle = this.secondTitleArr.toString()
        } else {
          this.secondTitle = ""
        }
        console.log(222, this.secondTitleArr, this.secondEndData)
      })
    } else if (index == 3) {
      //三级菜单
      setTimeout(() => {
        if (item.isChecked == true) {
          //选中
          this.thirdTitleArr.push(item.Meaning)
          this.thirdEndData.push(item)
          this.noRepeat2(this.thirdEndData)
        } else {
          //取消
          this.thirdEndData.filter((item, index) => {
            if (item.isChecked == false) {
              this.thirdEndData.splice(index, 1)
            }
          })
          this.thirdTitleArr.filter((item1, index) => {
            if (item.Meaning == item1) {
              this.thirdTitleArr.splice(index, 1)
            }
          })
        }
        if (this.thirdTitleArr.length > 0) {
          // 将回显数据转换成字符串
          this.thirdTitleArr.filter((item) => item.Meaning != undefined)
          this.thirdTitle = this.thirdTitleArr.toString()
        } else {
          this.thirdTitle = ""
        }
        console.log(333, this.thirdTitleArr, this.thirdEndData)
      })
    }
  }
  delitemTitle(e) {
    if (e == 1) {
      //删除一级菜单，当二级,三级菜单存在删除对应的回显数据
      this.itemTitle = ""
      if (this.itemList.length > 0) {
        this.itemList.forEach((item) => {
          item.isChecked = false
        })
        this.itemList = []
        this.itemTitleArr = []
      }
      this.secondTitle = ""
      if (this.secondList.length > 0) {
        this.secondList.forEach((item) => {
          item.isChecked = false
        })
        this.secondEndData = []
        this.secondTitleArr = []
        this.secondList = []
      }
      this.thirdTitle = ""
      if (this.thirdList.length > 0) {
        this.thirdList.forEach((item) => {
          item.isChecked = false
        })
        this.thirdEndData = []
        this.thirdTitleArr = []
        this.thirdList = []
      }
    } else if (e == 2) {
      // 删除二级菜单
      this.secondTitle = ""
      if (this.secondList.length > 0) {
        this.secondList.forEach((item) => {
          item.isChecked = false
        })
        this.secondEndData = []
        this.secondTitleArr = []
      }
      // 删除三级菜单
      this.thirdTitle = ""
      if (this.thirdList.length > 0) {
        this.thirdList.forEach((item) => {
          item.isChecked = false
        })
        this.thirdEndData = []
        this.thirdTitleArr = []
        this.thirdList = []
      }
    } else if (e == 3) {
      // 删除三级菜单
      this.thirdTitle = ""
      if (this.thirdList.length > 0) {
        this.thirdList.forEach((item) => {
          item.isChecked = false
        })
      }
      this.thirdEndData = []
      this.thirdTitleArr = []
    } else if (e == 4) {
      this.selectDate = ""
    } else if (e == 5) {
      this.endDate = "";
    }
  }
  setShowPage(isShow) {
    this.isShowHtml = isShow
    // if(isCallOut){
    this.serviceCode = 6
    console.log("显示外呼工单。。。。")
    // }
  }
  // 关联就诊人信息
  getServiceUserInfo(userPhoneNumber, CID, IsClose: string) {
    //页码归位
    this.currentCloudsImagePageIndex = 1
    this.currentHistoryPageIndex = 1
    this.isClosed = IsClose == "0" ? false : true
    this.httpService
      .toInteligentServicePost("api/BusinessService/GetUserCustomByPhone", {
        Phone: userPhoneNumber,
        cid: CID ? CID : "",
        IDCard: this.currentSelectPatientModel.IDCard
          ? this.currentSelectPatientModel.IDCard
          : "",
      })
      .subscribe(
        (result) => {
          this.isload = false
          let newResult = JSON.parse(JSON.stringify(result))
          console.log("关信息联就诊人==", newResult)
          if (result.errorcode == "00000000") {
            //成功
            this.serviceUserInfo = result.data.customModel
            if (this.serviceUserInfo) {
              this.serviceUserInfo.RegDate =
                this.serviceUserInfo.RegDate.replace("T", " ")
            }
            this.bindPatientList = result.data.patientModel
            this.IDCardInfoList = result.data.IDCardInfoList;
            this.getBindPatientList.emit({
              bindPatientList: this.bindPatientList,
            })
            if (result.data.patientModel.length > 0) {
              this.hasBindPatientInfo = true
            }
          }
        },
        (error: HttpErrorResponse) => {
          console.log(error)
        }
      )
  }

  //去重
  unique(arr) {
    for (var i = 0; i < arr.length; i++) {
      var isAlt = 0
      for (var j = 0; j < arr.length; j++) {
        if (arr[i].Phone == arr[j].Phone) {
          isAlt = isAlt + 1
          if (isAlt > 1) {
            arr.splice(j, 1)
            j--
            break
          }
        }
        if (i == arr.length - 1) {
          return arr
        }
      }
    }
    return arr
  }

  getRemarksItems() {
    if(localStorage.getItem('GetSysCode')){
      if(JSON.parse(localStorage.getItem('GetSysCode')).expires>new Date().getTime()){
        console.log('未超时')
        this.handleResultGetSysCode(JSON.parse(localStorage.getItem('GetSysCode')).value)
      }else{
        this.GetSysCode()
      }
    }else{
      this.GetSysCode()
    }
    
  }
  GetSysCode(){
    this.httpService
      .toInteligentServicePost("api/BusinessService/GetSysCode", {})
      .subscribe(
        (result) => {
          let t = new Date().getTime();
          let t1 = t+ (3600 * 1000 * 2)
          let obj = {
            value: result,
            expires: t1
          }
          localStorage.setItem('GetSysCode',JSON.stringify(obj))
          this.handleResultGetSysCode(result)
        },
        (error: HttpErrorResponse) => {
          console.log(error)
        }
      )
  }
  handleResultGetSysCode(result){
    console.log(result)
    this.isTestComplaints = true
          if (result.errorcode == "00000000") {
            this.patientNeedsItems = []
            this.complaintsDept = []
            this.QuesCategory = []
            result.data.forEach((element) => {
              if (element.CodeType == "142" && (element.Remark == this.currentSelectPatientModel.ModuleMark)) {
                // 患者需求-业务类型
                this.patientNeedsItems.push(element)
                localStorage.setItem("BussinessTypeList", JSON.stringify(this.patientNeedsItems));
              } else if (element.CodeType == "143") {
                // 投诉部门
                this.complaintsDept.push(element)
              } else if (element.CodeType == "144") {
                // 投诉问题类别  // 投诉分类
                element["isChecked"] = false
                this.arrList.push(element)
                localStorage.setItem("arrList", JSON.stringify(this.arrList))
                this.QuesCategory.push(element)
              } else if (element.CodeType == "147") {
                // 投诉人与患者关系
                this.patientRelationship.push(element)
              } else if (element.CodeType == "148") {
                // 投诉科室负责人
                this.deptLeader.push(element)
              }
            })
            localStorage.setItem("patientNeedsItems", JSON.stringify(this.patientNeedsItems));
            if (!this.deptManagerModel) {
              this.deptManagerModel = this.deptLeader[0]
            }
            if (!this.patientNeedsItem) {
              // 不设置默认值为第一个。默认值为请选择。
              // this.patientNeedsItem = this.patientNeedsItems[0]
            }
            if (!this.PersonnelRelation) {
              this.PersonnelRelation = this.patientRelationship[0]
            }
            setTimeout(() => {
              console.log(130190, $(".compayId"))
              if ($(".compayId")) {
                $(".compayId").searchableSelect()
                console.log(1000001111, $(".compayId"))
              }
              if (!this.addcomplaintsDept[0]) {
                this.addcomplaintsDept["请选择"]
              }

              // for (let i = 1; i < 4; i++) {
              //   $(`#dept${i}`).on("change", (e) => {
              //     if (e.target.value) {
              //       this.matching(0, i, e.target.value)
              //       this.matching(0, i, e.target.value)
              //       this.matching(0, i, e.target.value)
              //     } else {
              //       this.matching(0, i, "")
              //     }
              //   })
              // }
            })
          }
          this.re.detectChanges()
  }
  matching(x, y, z) {
    // x:1弹窗，y:所选select,z:当前select 的value值
    if (z) {
      if (y == 1) {
        // 投诉部门
        if (x == 0) {
          this.addcomplaintsDept[0] = z
        } else {
          // 弹窗
          // this.consultType = z;
        }
      }
      if (y == 2) {
        // 投诉部门
        if (x == 0) {
          this.addcomplaintsDept[1] = z
        } else {
          // 弹窗
          // this.consultType = z;
        }
      }
      if (y == 3) {
        // 投诉部门
        if (x == 0) {
          this.addcomplaintsDept[2] = z
        } else {
          // 弹窗
          // this.consultType = z;
        }
      }
    }
    // else {
    //  if (y == 2 ) {//投诉部门
    //     this.RecordSeekMedical = '';
    //   }
    // }
  }

  getRemarksHospital() {
    this.helper.showLoading()
    this.httpService
      .toInteligentServicePost("api/BusinessService/Get400CacheAllHospital", {})
      .subscribe(
        (result) => {
          this.helper.hideLoading();
          if (result.errorcode == "00000000") {
            this.remarksHospitalItems = result.data
          }
        },
        (error: HttpErrorResponse) => {
          console.log(error)
        }
      )
  }

  currentHistoryPageIndex: number = 1
  historyTotalSize: number = 0
  historyPerPageSize: number = 5
  getHistoryList(userPhoneNumber) {
    this.userPhone = userPhoneNumber
    // this.helper.showLoading()
    this.httpService
      .toInteligentServicePost("api/CallCenter/GetCALHistoryList", {
        CSID: this.CSID, // 客服id
        Phone: userPhoneNumber,
        PageIndex: this.currentHistoryPageIndex,
        PageSize: this.historyPerPageSize,
      })
      .subscribe(
        (result) => {
          this.helper.hideLoading()
          if (result.errorcode == "00000000") {
            //成功
            console.log(result.data.Rows)
            this.service400HistoryList = result.data.Rows
            console.log("来电改变===音频借口一次")
            this.service400HistoryList.forEach((element) => {
              element.AddTime = element.AddTime.replace("T", " ")
              // element.AddTime = element.AddTime.replace('T', ' ').replace(/-/g, '/');
            })
            this.historyTotalSize = result.data.Total
            if (result.data.Total > 0) {
              if (this.currentHistoryPageIndex == 1) {
                // this.judgeCanUpOrDown(1);
              }
              console.log("音频===jinali")
              this.hasHistory = true
              setTimeout(() => {
                this.addVideoControl()
              }, 100)
            } else {
              this.hasHistory = false
            }
          }
        },
        (error: HttpErrorResponse) => {
          console.log(error)
        }
      )
  }

  cloudsImageList: any[] = []
  currentCloudsImagePageIndex: number = 1
  userPID: string
  hasCloudsImage: boolean = false
  imagesTotalSize: number

  canPageDown: boolean = true
  canPageUp: boolean = false
  canImageDown: boolean = true
  canImageUp: boolean = false

  pageChangeButtonTapped(flag) {
    if (flag == 0) {
      // 首页
      this.currentHistoryPageIndex = 1
      this.getHistoryList(this.userPhone)
    } else if (flag == 1) {
      // 加
      var newListTotal = this.currentHistoryPageIndex * this.historyPerPageSize
      if (newListTotal < this.historyTotalSize) {
        this.currentHistoryPageIndex = this.currentHistoryPageIndex + 1
        this.getHistoryList(this.userPhone)
      }
    } else if (flag == 2) {
      // 减
      if (this.currentHistoryPageIndex > 1) {
        this.currentHistoryPageIndex = this.currentHistoryPageIndex - 1
        this.getHistoryList(this.userPhone)
      } else if (this.currentHistoryPageIndex == 1) {
        this.getHistoryList(this.userPhone)
      }
    }
    // this.judgeCanUpOrDown(pageType);
  }

  addVideoControl() {
    var videos = document.getElementsByTagName("video") //获取所有video
    console.log("音频==加载一次=", videos.length)
    if (videos.length == 0) return;
    //循环给所有video添加监听事件 当前的video开始播时  调用pauseAll 将当前播放的video的索引传值过去
    for (var i = videos.length - 1; i--; i >= 0) {
      console.log("音频==iiiii=", i)
        ; (function (n) {
          videos[n].addEventListener("play", function () {
            console.log("音频==addEventListener---", n)
            pauseAll(n)
          })
        })(i)
    }
    //接收调用传来的索引 循环所有video 索引与传来的索引不相同的 暂停 重载
    function pauseAll(index) {
      for (var j = videos.length - 1; j >= 0; j--) {
        if (j != index) {
          videos[j].pause()
          videos[j].load()
        }
      }
    }
  }

  //停掉所有的音频播放
  pauseAllRecordAudio(index) {
    console.log("音频==停掉一个")
    var videos = document.getElementsByTagName("video") //获取所有video
    videos[index].pause()
    // videos[index].load();
    //  调用pauseAll 将当前播放的video的索引传值过去
    // for (var i = videos.length - 1; i >= 0; i--) {
    //   (function (n) {
    //     pauseAll(n);
    //   })(i)
    // }
    //接收调用传来的索引 循环所有video 索引与传来的索引不相同的 暂停 重载
    // function pauseAll(index) {
    //   for (var j = videos.length - 1; j >= 0; j--) {
    //     if (j != index) {
    //       videos[j].pause();
    //       videos[j].load();
    //     }
    //   }
    // };
  }

  serviceAudioClick(item) {
    console.log(item)
  }
  // ts
  getValue(value) {
    console.log(value)
    this.editRemark = value
  }
  // 选择患者需求
  // selectPatientNeedsTapped(item) {
  //   console.log("选择患者需求", item)
  //   this.selectPatientNeedsCode = item.Code
  // }
  // 增加投诉部门
  addDept() {
    let dept = { Code: "" }
    this.addcomplaintsDept.push(dept)
  }
  // 处理状态
  cutDispose(flag) {
    console.log(flag)
    this.cutDisposeFlag = flag
  }

  // 归类三级分类数据
  classifyThreeLevelCategory() {
    // 相关问题数据
    let arrObj = {}
    let arrList = []
    //1.三级分类都有。
    if (this.thirdEndData.length > 0) {
      // 三级
      this.thirdEndData.forEach((item) => {
        arrObj["ThreeLevelName"] = item.Meaning
        arrObj["ThreeLevelCode"] = item.Code
        arrObj["TwoLevelCode"] = item.ParentCode
        // 二级
        this.secondEndData.filter((itemSecond) => {
          if (itemSecond.Code == item.ParentCode) {
            arrObj["TwoLevelName"] = itemSecond.Meaning
            arrObj["OneLevelCode"] = itemSecond.ParentCode
            // 一级
            this.itemList.filter((itemfirst) => {
              if (itemfirst.Code == itemSecond.ParentCode) {
                arrObj["OneLevelName"] = itemfirst.Meaning
              }
            })
          }
        })
        arrList.push(arrObj)
        arrObj = {}
      })
    }
    // 2.只有二级一级，没有三级
    if (this.secondEndData.length > 0 && this.thirdEndData.length == 0) {
      this.secondEndData.forEach((item2) => {
        var isExist = false
        this.thirdEndData.forEach((item3) => {
          if (item2.Code == item3.ParentCode) {
            isExist = true
          }
        })
        if (!isExist) {
          arrObj["TwoLevelCode"] = item2.Code
          arrObj["TwoLevelName"] = item2.Meaning
          arrObj["OneLevelCode"] = item2.ParentCode
          // 一级
          this.itemList.filter((item1) => {
            if (item1.Code == item2.ParentCode) {
              arrObj["OneLevelName"] = item1.Meaning
            }
          })
          arrList.push(arrObj)
          arrObj = {}
        }
      })
    }
    //3. 只有一级，没有二级，三级
    if (this.itemList.length > 0 && this.secondEndData.length == 0 && this.thirdEndData.length == 0) {
      this.itemList.forEach((item1) => {
        var isExist = false
        this.secondEndData.forEach((item2) => {
          if (item1.Code == item2.ParentCode) {
            isExist = true
          }
        })
        if (!isExist) {
          arrObj["OneLevelCode"] = item1.Code
          arrObj["OneLevelName"] = item1.Meaning
          arrList.push(arrObj)
          arrObj = {}
        }
      })
    }
    return arrList
  }

  clearClassify() {
    this.thirdEndData.length = 0;
    this.secondEndData.length = 0;
    this.itemList.length = 0;
    this.thirdTitleArr.length = 0;
    this.secondTitleArr.length = 0;
    this.itemTitleArr.length = 0;
    this.itemTitle = "";
    this.secondTitle = "";
    this.thirdTitle = "";
  }

  /**
   * 12345 自动保存电话
   */
  blurInputPhone() {
    if(this.currentSelectPatientModel.ModuleMark == '41' && this.currentWorkOrderInfo.CallPhone && this.currentWorkOrderInfo.CallPhone != '12345') {
      this.UpdateBusinessStats(true);
    }
  }
  // 保存工单-结束业务流程
  UpdateBusinessStats(isCheck = false) {
    console.time('11111111111111')
    let param = {
      BusID: this.currentSelectPatientModel.BusID,
      SourceType: "CUS",
      ModuleMark: this.currentSelectPatientModel.ModuleMark,
      CSID: this.CSID,
    }
    console.log('UpdateBusinessStats', param)
    let check = isCheck || this.checkRemarks(this.serviceCode);
    // 来电业务：就医服务投诉
    if (this.serviceCode == 5) {
      let evalTypeList = this.classifyThreeLevelCategory()
      param["EvalTypeList"] = evalTypeList // 三级分类
      param["EvalName"] = this.complaint;
      param["PatientName"] = this.PatientName // 患者姓名
      param["PersonnelRelation"] = this.PersonnelRelation.Code // 投诉人与患者关系
      param["ContactNumber"] = this.ContactNumber // 联系人电话
      param["ComplaintsJsonStr"] = this.addcomplaintsDept;
      param["DocCID"] = this.deptManagerModel.Remark
    }
    // 外呼工单
    if (this.serviceCode == 6) {
      param["otherContacter"] = this.otherContacter
      param["otherContactNumber"] = this.otherContactNumber
    }

    // 咨询
    if (this.serviceCode == 3) {
      param["PatientName"] = this.PatientName; // 患者姓名
      param["ContactNumber"] = this.ContactNumber; // 联系人电话
      param["InvolveDept"] = this.ConsultDept; // 联系人部门
    }

    // 未完成0-进行中2-待处理3-已转办4-待确认5-已完成6-作废-1
    if (!isCheck) {
      param["Status"] = this.cutDisposeFlag;
      this.currentWorkOrderInfo.IsClose = this.cutDisposeFlag;
    }
    else param["Status"] = this.currentWorkOrderInfo && this.currentWorkOrderInfo.IsClose;
    //关联的工单
    let WorkOrderListParam = [];
    if (this.isCurrent) {
      if (this.workorderManagerService.getWorkOrderList().length > 0) {
        this.workorderManagerService.getWorkOrderList().forEach(element => {
          let relateItem = {};
          if (element.PID && !element.RegCode) {//患者工单
            relateItem = { WorkType: 1, PID: element.PID };
          } else if (element.RegCode) {//门诊和互联网挂号工单
            let WorkType = element.SourceCode == "10" ? 3 : 2;
            relateItem = { WorkType: WorkType, PID: element.PID, RegCode: element.RegCode, DeptID: element.DeptID, DocID: element.DocID, HID: element.HID ? element.HID : (WorkType == 2 ? element.OfflineHID : element.OnlineHID) };
          } else if (element.PlanType) {//互联网出诊工单
            relateItem = { WorkType: 4, DeptID: element.DeptID, DocID: element.DocID, HID: element.HID, PlanType: element.PlanType };
          } else if (element.WorkType == 5) {
            relateItem = { WorkType: 5, HistoryBusID: element.HistoryBusID };
          }
          if(Object.keys(relateItem).length !== 0) {
            WorkOrderListParam.push(relateItem);
          }
        });
      }
    } else {//历史关联工单
      if (this.historyWorkOrderLsit.patientList.length > 0) {//患者类型
        this.historyWorkOrderLsit.patientList.forEach(element => {
          let relateItem = {};
          if (element["isRelated"] == true) {
            relateItem = { WorkType: 1, PID: element.PID };
            WorkOrderListParam.push(relateItem);
          }

        });
      }

      if (this.historyWorkOrderLsit.resList.length > 0) {//患者类型
        this.historyWorkOrderLsit.resList.forEach(element => {
          let relateItem = {};
          if (element["isRelated"] == true) {
            let WorkType = (element.SourceCode == 10) ? 3 : 2;
            relateItem = { WorkType: WorkType, PID: element.PID, RegCode: element.RegCode, DeptID: element.DeptID, DocID: element.DocID, HID: element.HID ? element.HID : (WorkType == 2 ? element.OfflineHID : element.OnlineHID) }; WorkOrderListParam.push(relateItem);
          }
        });
      }

      if (this.historyWorkOrderLsit.realList.length > 0) {//患者类型
        this.historyWorkOrderLsit.realList.forEach(element => {
          let relateItem = {};
          if (element["isRelated"] == true) {
            relateItem = { WorkType: 4, DeptID: element.DeptID, DocID: element.DocID, HID: element.HID, PlanType: element.PlanType };
            WorkOrderListParam.push(relateItem);
          }
        });
      }
    }


    param["WorkOrderList"] = WorkOrderListParam;

    // 来电业务：医卡通预约挂号
    // if (this.currentSelectPatientModel.ModuleMark == "11") {
    param["QuesClassify"] = this.patientNeedsItem && this.patientNeedsItem.Code ? this.patientNeedsItem.Code : ''
    // param["Solution"] = this.other
    // } else {
    param["Remark"] = this.recordDiscription.replace(/\r\n/g, "") //问题描述
    param["Solution"] = this.recordSolution.replace(/\r\n/g, "") // 客服解决方案
    if (this.currentSelectPatientModel.ModuleMark == "41" && this.currentWorkOrderInfo.CallPhone && this.currentWorkOrderInfo.CallPhone != '12345') param["CallPhone"] = this.currentWorkOrderInfo.CallPhone;
    // }
    check && this.helper.showLoading()
    check &&
      this.httpService
        .toInteligentServicePost("api/BusinessService/UpdateBusinessStats", {
          ...param,
        })
        .subscribe(
          (result) => {
            !check && this.helper.hideLoading();
            GlobalData.isChangeOperator = true;
            console.timeEnd('11111111111111');
            if (isCheck) return;
            this.workorderManagerService.setWorkOrderList([]);
            if (result.errorcode == "00000000") {
              this.helper.toast("处理成功！")
              this.isSevriceRemark = null
              this.relateOrderList.length = 0;
              this.getBindPatientList.emit({
                isSevriceRemark: "3",
              })
              this.currentSelectPatientModel.isSave = "1"
              this.currentSelectPatientModel.IsClose = "0"
              $("#moduleDetailBg").css("background", "#F7F9FE")
            } else {
              this.helper.toast(result.message)
            }
          },
          (error: HttpErrorResponse) => {
            console.log(error)
            this.isShowHtml = false
            this.helper.hideLoading()
          }
        )
  }
  checkNoNull(text) {
    this.helper.toast(`${text}不能为空！`)
  }
  noRepeat2(arr) {
    var newArr = []
    for (var i = 0; i < arr.length; i++) {
      if (newArr.indexOf(arr[i]) == -1) {
        newArr.push(arr[i])
      }
    }
    return newArr
  }
  // check备注
  checkRemarks(flag) {
    // 投诉问题类别
    console.log(flag);
    if (!this.complaint && flag == "5") {
      this.helper.toast("投诉人不能为空！")
      return false
    } else if (!this.PatientName && flag == "5") {
      this.helper.toast("患者姓名不能为空！")
      return false
    } else if (!this.PersonnelRelation.Code && flag == "5") {
      this.helper.toast("与患者关系不能为空！")
      return false
    }
    else if (!this.ContactNumber && flag == "5") {
      this.helper.toast("联系方式不能为空！")
      return false
    } else if (this.itemList.length == 0 && flag == "5") {
      this.helper.toast("投诉一级分类不能为空！")
      return false
    } else if (this.addcomplaintsDept && flag == "5" && this.serviceCode == 5) {//投诉转办
      let nulldept = false;
      let nullPerson = false;
      let nullduty = false;
      this.addcomplaintsDept.forEach(element => {
        if (element.InvolveDept.includes("搜")) nulldept = true;
        if (!element.InvolvePerson) nullPerson = true;
        if (!element.DocCID) nullduty = true;
      });
      if (nulldept) {
        this.helper.toast("投诉部门不能为空！"); return false;
      }
      if (nullPerson) {
        this.helper.toast("被投诉人不能为空！"); return false;
      }
      if (nullduty) {
        this.helper.toast("科室负责人不能为空！"); return false;
      }

    } else if (this.recordDiscription.length == 0) {
      this.helper.toast("问题描述不能为空！")
      return false
    } else if (this.recordSolution.length == 0) {
      this.helper.toast("解决方案不能为空！")
      return false
    } else if(this.currentSelectPatientModel.ModuleMark == '41' && (!this.currentWorkOrderInfo.CallPhone || this.currentWorkOrderInfo.CallPhone == '12345')) {
      this.helper.toast("请填写联系方式！")
      return false
    }
    // 1.	工单文字去掉限制200字
    // else if (this.recordDiscription.length > 300) {
    //   this.helper.toast("问题描述不能多于300字！")
    //   return false
    // } else if (this.recordSolution.length > 300) {
    //   this.helper.toast("解决方案不能多于300字！")
    //   return false
    // } 
    else if (!this.patientNeedsItem.Code) {
      this.helper.toast("请选择业务类型！")
      return false
    }

    return true
  }
  //未选对应一级下的二级菜单给出提示
  getTips() {
    var firstCode = []
    var secondCode = []
    this.itemList.forEach((item) => {
      firstCode.push(item.Code)
    })
    firstCode = this.noRepeat2(firstCode)
    this.secondEndData.forEach((item) => {
      secondCode.push(item.ParentCode)
    })
    secondCode = this.noRepeat2(secondCode)
    if (firstCode.length != secondCode.length) {
      this.helper.toast("请继续勾选对应的相关问题详细分类!")
      return false
    } else {
      return true
    }
  }
  PhoneOld: String = ""
  isTestComplaints: boolean = true
  isload = false
  callinsterval = null
  ngOnChanges(changes: SimpleChanges) {
    if (changes["currentSelectPatientModel"]) {
      this.getRemarksItems();
      this.isShowServiceList = false;
      this.callinsterval = setTimeout(() => { 
        console.log('切换');
        if(GlobalData.isChangeOperator) {
          // GlobalData.isChangeOperator = false;
          this.refreshData();
          clearInterval(this.callinsterval);
          this.callinsterval = null;
        }
      }, 1000);
      
    }
  }

  refreshData() {
    this.helper.showLoading();
    this.workorderManagerService.addLog("centercalll=================");
    this.workorderManagerService.getLogges();
    this.patientInfo = null;
    this.RegList.length = 0;
    this.OutPatientRegCode = "";
    this.IDCardNumber = "";
    this.selectDate = "";
    this.endDate = "";
    this.isShowResult = false;
    this.isload = true;
    this.recordDiscription = "";
    this.recordSolution = "";
    this.clearClassify();
    let audio = document.getElementById('work-order-recording') as any;
    if (audio !== null) {
      audio.src = "";
    }
    console.log("来电改变===", this.currentSelectPatientModel)
    this.isTestComplaints = false
    console.log("来电改变===运行了结构结构")
    this.PhoneOld = this.currentSelectPatientModel.Phone
    this.other = ""
    this.addcomplaintsDept = []
    this.service400HistoryList = []
    this.patientNeedsItem = new RemarkItemsModel();
    this.recordDiscription = "" //问题描述
    if (this.currentSelectPatientModel) {
      this.recordDiscription = this.currentSelectPatientModel.Remark != null ? this.currentSelectPatientModel.Remark : ""
    }
    this.recordSolution = "" // 客服解决方案
    this.cutDisposeFlag = 1
    this.duty = null
    this.complaint = null
    this.getServiceUserInfo(
      this.currentSelectPatientModel.Phone,
      this.currentSelectPatientModel.CID,
      this.currentSelectPatientModel.IsClose
    )
    this.getHistoryList(this.currentSelectPatientModel.Phone)
    this.queryOrderInfo()//刷新页面
    this.currentPatientModel = JSON.parse(JSON.stringify(this.currentSelectPatientModel))

  }

  historyInfoClick(item, index) {
    this.pauseAllRecordAudio(index)
    this.ishistoryPopup = true
    this.historyInfoRow = item
  }

  setNOCall() {
    this.helper.toast("接听电话禁止播放")
  }

  delete(index) {
    if (this.addcomplaintsDept.length > 1) {
      this.addcomplaintsDept.splice(index, 1)
      // this.dept111 = this.addcomplaintsDept[0]
    }
    setTimeout(() => {
      $(".compayId").searchableSelect()
      for (let i = 1; i < 4; i++) {
        $(`#dept${i}`).on("change", (e) => {
          if (e.target.value) {
            this.matching(0, i, e.target.value)
          } else {
            this.matching(0, i, "")
          }
        })
      }
    })
    if (this.iselectChange) {
      this.iselectChange = false
    } else {
      this.iselectChange = true
    }
    this.re.detectChanges()

  }
  // 模糊搜索//获取字符传的首字母集合
  getFrist(arrlist, num) {
    this.noRepeat2(arrlist)
    arrlist.forEach((item) => {
      var pyinyinList = pinyin(item.Meaning, {
        heteronym: true,
        style: pinyin.STYLE_FIRST_LETTER,
      })
      var pyJson = ""
      pyinyinList.forEach((itemPY) => {
        pyJson = pyJson + "" + itemPY[0]
      })
      //将所有多音字符筛选出放在result中
      var str = ""
      var result = [""]
      var temp = []
      for (let i = 0; i < pyinyinList.length; i++) {
        temp = result
        result = []
        for (let j = 0; j < temp.length; j++) {
          for (let k = 0; k < pyinyinList[i].length; k++) {
            str = temp[j] + pyinyinList[i][k]
            result.push(str)
            str = ""
          }
        }
      }
      item.first = result
    })
    if (num == 1) {
      this.fuzzySearchListFiex = arrlist
    } else if (num == 2) {
      this.fuzzySearchSecondList = arrlist
    } else if (num == 3) {
      this.fuzzySearchThirdList = arrlist
    }
  }
  //正则表达式//keyWord 关键字//str 匹配字符串
  getKeyList(num) {
    var newList = []
    var reg = new RegExp(this.serchName)
    var reg2 = new RegExp(this.serchSecondName)
    if (num == 1) {
      this.fuzzySearchListFiex.forEach((item) => {
        //如果字符串中不包含目标字符会返回-1
        if (item.first.length > 0) {
          for (let i = 0; i < item.first.length; i++) {
            if (item.Meaning.match(reg) || item.first[i].match(reg)) {
              if (newList.indexOf(item) == -1) {
                newList.push(item)
              }
            }
          }
        }
      })
      this.noRepeat2(newList)
      this.QuesCategoryParent = newList
    } else if (num == 2) {
      this.fuzzySearchSecondList.forEach((item) => {
        if (item.first.length > 0) {
          for (let i = 0; i < item.first.length; i++) {
            if (item.Meaning.match(reg2) || item.first[i].match(reg2)) {
              //匹配成功do something
              if (newList.indexOf(item) == -1) {
                newList.push(item)
              }
            }
          }
        }
      })
      this.noRepeat2(newList)
      this.secondList = newList
    } else if (num == 3) {
      this.fuzzySearchThirdList.forEach((element) => {
        if (element.first.length > 0) {
          for (let i = 0; i < element.first.length; i++) {
            if (element.Meaning.match(reg2) || element.first[i].match(reg2)) {
              // 匹配成功do something
              if (newList.indexOf(element) == -1) {
                newList.push(element)
              }
            }
          }
        }
      })
      this.noRepeat2(newList)
      this.thirdList = newList
    }
  }

  /**
   * 根据BusID获取历史关联工单
   */
  GetWorkOrderByBusID(BusID: string) {
    this.httpService
      .toInteligentServicePost("api/CallCenter/GetWorkOrderByBusID", {
        BusID: BusID,
      }).subscribe(
        (result) => {
          if (result.errorcode == "00000000") {
            if (result.data) {
              this.historyWorkOrderLsit = result.data;
              this.historyWorkOrderLsit["hasList"] = false;
              if (this.historyWorkOrderLsit.patientList.length > 0) {//患者类型
                this.historyWorkOrderLsit["hasList"] = true;
                this.historyWorkOrderLsit.patientList.forEach(element => {
                  element["isRelated"] = true;
                  element.Age = this.getAgeFromBirthDay(element.Birthday);
                });
              }

              if (this.historyWorkOrderLsit.resList.length > 0) {//患者类型
                this.historyWorkOrderLsit["hasList"] = true;
                this.historyWorkOrderLsit.resList.forEach(element => {
                  element["isRelated"] = true;
                });
              }

              if (this.historyWorkOrderLsit.realList.length > 0) {//患者类型
                this.historyWorkOrderLsit["hasList"] = true;
                this.historyWorkOrderLsit.realList.forEach(element => {
                  element["isRelated"] = true;
                });
              }

            }
          }
        },
        (error: HttpErrorResponse) => {
          console.log(error)
        }
      )
  }
  totalPage: number = 0;
  querypatientInfo(type) {//type==1 页面内点击查询 type==2上一页下一页查询
    if (this.IDCardNumber.length == 0 && this.OutPatientRegCode.length == 0) {
      this.helper.toast("请输入门诊号或身份证号码！")
      return
    }

    if (type == 1) {
      this.queryPageIndex = 1;
    }

    this.isShowResult = true;
    this.httpService
      .toInteligentServicePost("api/CallCenter/GetRegisterAndPatientBySearch", {
        RegCode: this.OutPatientRegCode ? this.OutPatientRegCode : "",
        IDCard: this.IDCardNumber ? this.IDCardNumber : "",
        // RegDate: this.selectDate ? this.selectDate : "",
        StartTime: this.selectDate,
        EndTime: this.endDate,
        HID: "",
        PageIndex: this.queryPageIndex,
        PageSize: this.queryPageSize,
      })
      .subscribe(
        (result) => {
          console.log(100, result)
          if (result.errorcode == "00000000") {
            this.totalPage = Math.floor(Number(result.data.RegList.Total) / this.queryPageSize);
            this.totalPage += Number(result.data.RegList.Total) % this.queryPageSize > 0 ? 1 : 0;
            this.patientInfo = result.data.PatientInfo
            this.patientInfo.Age = this.getAgeFromBirthDay(this.patientInfo.Birthday)
            this.patientInfo.SexMeaning = this.getSexMeaningBy(this.patientInfo.Sex)
            if (this.workorderManagerService.getWorkOrderList().filter(element => element.PID == this.patientInfo.PID && !element.RegCode).length == 0) {//没有，新增
              this.patientInfo['isRelated'] = false;
            } else {//已有
              this.patientInfo['isRelated'] = true;
            }
            this.RegList.length = 0
            result.data.RegList.Rows.forEach((element) => {
              element.Age = this.getAgeFromBirthDay(element.Birthday)
              element.SexMeaning = this.getSexMeaningBy(element.Sex)
              element.RegDate = element.RegDate.match(/(\S*)T/)[1]
              element["isRelated"] = false;//是否已关联
              this.workorderManagerService.getWorkOrderList().forEach((reelement) => {
                if (reelement.RegCode == element.RegCode) {
                  element["isRelated"] = true;
                }
              })
            })
            this.queryTotal = result.data.RegList.Total
            this.RegList = result.data.RegList.Rows
            console.log(this.RegList)
          }
        },
        (error: HttpErrorResponse) => {
          console.log(error)
        }
      )
  }
  getAgeFromBirthDay(birthday: string) {
    if (birthday.includes("T")) birthday = birthday.split("T")[0];
    let currentYear = new Date().getFullYear() //当前的年份
    let calculationYear = new Date(birthday).getFullYear() //计算的年份
    const wholeTime = currentYear + birthday.substring(4) //周岁时间
    const calculationAge = currentYear - calculationYear //按照年份计算的年龄
    //判断是否过了生日
    if (new Date().getTime() > new Date(wholeTime).getTime()) {
      return calculationAge
    } else {
      return calculationAge - 1
    }
  }
  getSexMeaningBy(sex) {
    if (sex == 1) return "男"
    if (sex == 2) return "女"
    return "未知"
  }
  currentType: number = 1;
  choosetime(type) {
    this.currentType = type;
    this.isShowDateChooser = !this.isShowDateChooser
    $("#call-visit-date-choose-bg").show()
  }
  cancelchooseVisitDate() {
    this.isShowDateChooser = !this.isShowDateChooser
  }
  onSelectDateSelect(day: string) {
    this.isShowDateChooser = false
    if (this.currentType == 1) {//开始时间
      this.selectDate = day
    } else {
      this.endDate = day;
    }
    console.log(day)
  }
  showQeuryResult(type) {
    if (type == 0) {
      this.isShowResult = false
    } else if (type == 1) {
      this.isShowResult = true
    }
  }

  /**
   * 关联工单方法
   * @param type 
   * @param info 
   * @returns 
   */

  relateOrder(type, info, check?: boolean, inPage?: boolean, deleteConfig = false) {
    console.log(info);
    let related = check ? true : this.workorderManagerService.setWorkOrderModel(info, type);
    let recordSolutionTemp = '';
    if (type == 1 && related) {
      recordSolutionTemp += `就诊人：${info.Name} ${info.SexMeaning} ${info.Age} | 身份证号：${info.IDCard}`
    } else if (type == 2 && related) {
      recordSolutionTemp += `科室：${info.DeptName}|医生：${info.DoctorName}|出诊时间：${info.RegDate ? info.RegDate : info.VisitDate} ${info.APWMeaning}|挂号状态：${info.RegStatusMeaning ? info.RegStatusMeaning : info.StatusMeaning}|挂号编号：${info.RegCode}|挂号费：¥${info.TotalPay}|就诊人：${info.PatientName} ${info.SexMeaning} ${info.Age}|身份证号：${info.IDNumber}`;
    } else if (type == 3 && related) {//互联网出诊
      recordSolutionTemp += `科室：${info.DeptName} | 医生：${info.DoctorName} | 问诊方式：${info.PlanTypeName}`
    } else if (type == 4 && related) {//互联网出诊
      recordSolutionTemp += `科室：${info.DeptName} | 医生：${info.DoctorName} | 诊断：${info.Diognoise} | 就诊人：${info.PatientName}`
    } else if (type == 5 && related) {// 历史服务
      recordSolutionTemp += ` 工单号：${info.CallID}`
    }
    if (deleteConfig) {
      this.recordSolution = this.recordSolution.replace(recordSolutionTemp, '');
      this.recordDiscription = this.recordDiscription.replace(recordSolutionTemp, '');
    } else {
      if (inPage) return;
      if (!this.recordSolution.includes(recordSolutionTemp)) {
        this.recordSolution += recordSolutionTemp;
      }
      if (!this.recordDiscription.includes(recordSolutionTemp)) {
        this.recordDiscription += recordSolutionTemp;
      }
    }
  }

  /**
 * 关联历史工单方法
 * @param type 
 * @param info 
 * @returns 
 */
  relateHistoryOrder(type, info) {
    info.isRelated = !info.isRelated;
    console.log(this.historyWorkOrderLsit);
  }

  /**
   * 获取历史服务详情
   * @param serviceInfo 
   */
  getHistoryServiceDetail(serviceInfo) {
    console.log(serviceInfo);
    this.historyPatientModel = serviceInfo;
    this.historyPatientModel["IsClose"] = serviceInfo.Status;
    this.currentSelectPatientModel = this.historyPatientModel;
    this.clearClassify();
    this.configServiceInfo();
    this.GetWorkOrderByBusID(serviceInfo.BusID);//获取历史工单
    this.isHistoryService = true;
    this.isCurrent = false;
  }
  /**
   * 展示预约系统
   */
  showAppointSystem() {
    this.isShowAppointSystem = true;
    if (!this.appointSystemURL) {
      this.httpService
        .toInteligentServicePost("api/BusinessService/GetSysConfig", {
          Key: "CallCenterAppointAddress"
        })
        .subscribe(
          (result) => {
            if (result.errorcode == "00000000") {
              console.log(this.currentSelectPatientModel);
              console.log(`${result.data.ConfigValue}?LoginName=${this.customerServiceMesage.AppointSysName}&PassWord=${this.customerServiceMesage.AppointSysPwd}&PhoneForCallCenter=${this.currentSelectPatientModel.Phone}`);
              this.appointSystemURL = `${result.data.ConfigValue}?LoginName=${this.customerServiceMesage.AppointSysName}&PassWord=${this.customerServiceMesage.AppointSysPwd}&PhoneForCallCenter=${this.currentSelectPatientModel.Phone}`;
              $("#appoint-frame").attr("src", this.appointSystemURL);
              // $("#appoint-frame").attr("src", "https://192.168.3.201:9081/LoginForCallCenter.aspx?LoginName=admin1&PassWord=000000&PhoneForCallCenter=18202244394");
              // window.open(this.appointSystemURL);
              // $("#appoint-frame").attr("src", "https://yy.careate.cn/");

            }
          },
          (error: HttpErrorResponse) => {
            console.log(error)
          }
        )
    }
    // else {
    //   $("#appoint-frame").attr("src", this.appointSystemURL);
    // }

  }

  /**
   * 选择工单类型
   * @param type 1当前工单 ；0历史工单
   */
  chooseWorkOrderWithType(type: Number) {
    if (this.isHistoryService) {
      this.isCurrent = (type == 1);
      this.currentSelectPatientModel = type == 1 ? this.currentPatientModel : this.historyPatientModel;
      this.clearClassify();
      this.configServiceInfo();
    }
  }

  pageControlBtnClick($event, index) {
    if (this.queryPageIndex == 1) {
      // 当前页面为第1页的时候，点上一页和 回到首页 return
      if (index == -1 || index == 0) {
        return
      }
    }
    // 超出总大小，点击下一页无效
    if (this.queryPageIndex * this.queryPageSize >= this.queryTotal) {
      if (index == 1) {
        return
      }
    }
    if (index == 0) {
      this.queryPageIndex = 1
    } else {
      this.queryPageIndex += index
    }
    this.querypatientInfo(2)
  }
}
