import { SettingsProvider } from './providers/theme.providers';
import { NgModule } from '@angular/core';
import { BrowserModule ,HammerModule} from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { PhotoLibrary } from '@ionic-native/photo-library/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Network } from '@ionic-native/network/ngx';
import { AppMinimize } from '@ionic-native/app-minimize/ngx';
import { IonicStorageModule } from '@ionic/storage';

// 修复浏览器访问白屏问题
import { LocationStrategy, HashLocationStrategy } from '@angular/common';

// 拍照/选择图片
import { Camera } from '@ionic-native/camera/ngx';

// 二维码扫描
import { QRScanner } from '@ionic-native/qr-scanner/ngx';

//中枢系统
import { WebimMethodService } from './common/webim-method.service';
import { WebimService } from './common/webim.service';
import { WebIMconfig } from './common/WebIMconfig';
import { SelectDateModule } from './module/select-date/select-date.module';
import { Service400Module } from './module/service400/service400.module'
import { CallServiceModule } from './module/call-service/call-service.module'
import { CustomPaginationModule } from './module/custom-pagination/custom-pagination.module';
import { IonicGestureConfig } from './common/HammerGestureConfig';
import { from } from 'rxjs';
import { SearchSelectModule } from './module/search-select/search-select.module';
import { WorkorderManagerServicesModule } from './common/workorder-manager.module';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    IonicStorageModule.forRoot({
      name: '__qyidb',
      driverOrder: ['sqlite', 'websql', 'indexeddb']
    }),
    BrowserModule,
    HammerModule,
    IonicModule.forRoot({
      hardwareBackButton: true,
      rippleEffect: false,
      mode: 'ios',
      backButtonText: '返回',
      statusTap: true,
      swipeBackEnabled: true
      
    }),
    WorkorderManagerServicesModule.forRoot(),
    AppRoutingModule, HttpClientModule,SelectDateModule,Service400Module,CustomPaginationModule,SearchSelectModule,CallServiceModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    StatusBar,
    SplashScreen,
    AppVersion,
    SocialSharing,
    PhotoLibrary,
    InAppBrowser,
    Network,
    AppMinimize,
    QRScanner,
    Camera,
    SettingsProvider
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
