import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BasePage } from 'src/app/common/basepage';
import { DayViewModel } from 'src/app/model/DayViewModel';
import { NavController, AlertController } from '@ionic/angular';
import { HttpService } from 'src/app/providers/HttpService';
import { Utils } from 'src/app/providers/Utils';
import { StorageManage } from 'src/app/providers/StorageManage';
import { Helper } from 'src/app/providers/Helper';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-select-date',
  templateUrl: './select-date.component.html',
  styleUrls: ['./select-date.component.scss'],
})
export class SelectDateComponent extends BasePage {

  //-------页面Model------
  dateTitle: string
  changmonth: number = 0;
  daymodel: DayViewModel[]

  @Input()
  noselectDate: string
  @Input()
  defaultDate: string
  @Input()
  minDate: Date
  @Input()
  maxDate: Date
  @Input()
  showMonth: Date
  @Input()
  RemainsNums:string;
  @Input()
  controlType:string;//日期选择类型：checkup:体检
  @Output()
  CallBackTitle: EventEmitter<string> = new EventEmitter();
  @Input()
  selectMonth: string
  @Input()
  currentDate:string;
  constructor( public nav: NavController,
    public service :HttpService,public alertCtr:AlertController,
    public utils :Utils,public storage :StorageManage,public helper:Helper,public route:ActivatedRoute) {
    super(utils, nav, route)
  }

  //-----初始-----
  _ngOnInit() {
    // console.log("=================" + this.controlType);
      this.openCalendar()
  }
  openCalendar(setmonth: number = 0) {
      this.InitCalendar(setmonth);
  }
  InitCalendar(setmonth: number) {


      var nowdate = new Date()
      //如果传来值则设为默认值
      if (this.defaultDate&&this.defaultDate.length!=0)
          nowdate = new Date(this.defaultDate);
      if (this.showMonth)
          nowdate = new Date(this.showMonth)
      // if (this.defaultDate && this.defaultDate != "") {
      //     var selyear = parseInt(this.defaultDate.split('-')[0]);
      //     var selmonth = parseInt(this.defaultDate.split('-')[1]);
      //     var selday = parseInt(this.defaultDate.split('-')[2]);
      //     nowdate = new Date(selyear, (selmonth - 1), selday);
      // }
      this.daymodel = []
      //打开等待框
      var nowyear = nowdate.getFullYear()//当前年
      var nowmonth = nowdate.getMonth() + 1//当前月
      var nowday = nowdate.getDate()//当前日
      // console.log(nowmonth+"=="+nowday+"||"+this.noselectDate);
      
      if (setmonth == 1)
          this.changmonth++
      else if (setmonth == -1)
          this.changmonth--
      nowdate.setMonth(nowdate.getMonth() + this.changmonth, 1)

      var tomonth = new Date(nowdate.getFullYear(), nowdate.getMonth(), 1)//当月第一天


      var cnMonth = ""
      switch ((nowdate.getMonth() + 1)) {
          case 1: cnMonth = "一"; break;
          case 2: cnMonth = "二"; break;
          case 3: cnMonth = "三"; break;
          case 4: cnMonth = "四"; break;
          case 5: cnMonth = "五"; break;
          case 6: cnMonth = "六"; break;
          case 7: cnMonth = "七"; break;
          case 8: cnMonth = "八"; break;
          case 9: cnMonth = "九"; break;
          case 10: cnMonth = "十"; break;
          case 11: cnMonth = "十一"; break;
          case 12: cnMonth = "十二"; break;
      }
      this.dateTitle = cnMonth + "月 " + nowdate.getFullYear()

      var monthfirstweeknow = tomonth.getDay()//当月第一天星期几

      var premonth = monthfirstweeknow
      for (var i = 1; i <= 42; i++) {
          var day = new DayViewModel();
          day.RemainNumber = "0";
          if (i > monthfirstweeknow) {
              tomonth.setDate(tomonth.getDate() + (i == 1 ? 0 : 1))
            //   day.IsEnabled = true;
          }
          else {
              tomonth.setDate(tomonth.getDate() - premonth)
              //day.IsEnabled = false;
              //day.IsSelected = false;
              premonth = -1
          }
          day.IsEnabled = true;
          day.Year = tomonth.getFullYear();
          day.Month = (tomonth.getMonth() + 1);
          day.Day = tomonth.getDate();

          if (!this.showMonth&&this.defaultDate.length!=0)
              day.IsSelected = nowyear + "" + nowmonth + "" + nowday == day.Year + "" + day.Month + "" + day.Day;

          if (day.Month > (nowdate.getMonth() + 1)) {
              // day.IsEnabled = false;
              day.IsSelected = false;
          }

          if (new Date(day.Year, day.Month - 1, day.Day) < this.minDate ||
              new Date(day.Year, day.Month - 1, day.Day) > this.maxDate) {
              day.IsEnabled = false;
              day.IsSelected = false;
          }
          this.daymodel.push(day)
          if(this.currentDate){
            this.daymodel.forEach(e=>{
                if(e.Month==Number(this.currentDate.split('-')[1])&&e.Day==Number(this.currentDate.split('-')[2])){
                    e.IsSelected=true;
                }
            })
          }

      }
      if (this.noselectDate != null) {
          //过滤禁选的日期
          this.daymodel.forEach((day) => {


              //和禁选日期吻合并处于可选状态
              if (day.IsEnabled && this.noselectDate.indexOf("|" + day.Year + "-" + day.Month + "-" + day.Day + "|") != -1) {
                  //console.log(day.Year + "" + day.Month + "" + day.Day + "==" + this.noselectDate);
                  day.IsEnabled = false;
              }
          })
      }
      if(this.RemainsNums){
          console.log(this.RemainsNums);
                var tempRemainNumbers = this.RemainsNums.split("|");
                var j = 0;
                for(var i=0; i<this.daymodel.length;i++){
                    if(this.daymodel[i].IsEnabled){
                        this.daymodel[i].RemainNumber = tempRemainNumbers[j];
                        j++;
                    }
                }
      }
      

  }
  selDate(item: DayViewModel) {
      if (item.IsEnabled || this.selectMonth) {
          this.daymodel.forEach(s => {
              s.IsSelected = false
          })
          item.IsSelected = true

          this.CallBackTitle.emit(item.Year + "-" + this.storage.GenerateID(item.Month) + "-" + this.storage.GenerateID(item.Day));
      }
  }
  //清除选择状态
  clearDate() {
      this.daymodel.forEach(s => {
          s.IsSelected = false
      })
  }
  stopPropagation(e){
      e.stopPropagation();
  }

}
