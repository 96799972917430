// environment example file
// You should copy this file and rename to `environment.conf.ts` (do NOT add to version control)
// place your own config to that file

export const devServers = {
  // 开发环境(外网)
  // authServerUrl: "http://careate.vicp.net:38011/",
  // appServerUrl: 'http://careate.vicp.net:38012/',
  // fileServerUrl: 'http://careate.vicp.net:8199/',
  // ossServerUrl: 'http://careate.vicp.net:38012/',
  // chatsServerUrl: 'https://qyi.careate.cn/',
  // authServerUrl: "http://192.168.1.31:8011/",
  // appServerUrl: 'http://192.168.1.31:8012/',
  // fileServerUrl: 'http://192.168.1.31:8199/',
  // ossServerUrl: 'http://192.168.1.31:8013/',
  // chatsServerUrl: 'https://qyi.careate.cn/',

  // 开发环境(IP)
  // authServerUrl: "https://192.168.1.31:8011/",
  // appServerUrl: "https://192.168.1.31:8812/",
  // fileServerUrl: "https://192.168.1.31:8399/",
  // ossServerUrl: "https://192.168.1.31:8812/",
  // chatsServerUrl: "https://qyi.careate.cn/",
  //测试环境
  // authServerUrl:"http://192.168.3.201:8011/",
  // appServerUrl: 'http://192.168.3.201:8012/',
  // fileServerUrl: 'http://192.168.3.201:8013/',
  // ossServerUrl: 'http://192.168.3.201:8012/',
  // chatsServerUrl: 'https://qyi.careate.cn/',

  //测试环境--（呼叫中心测试用）
  // authServerUrl: "https://192.168.3.201/",
  // appServerUrl: 'https://192.168.3.201:7012/',
  // fileServerUrl: 'https://192.168.3.201:7199/',
  // ossServerUrl: 'https://192.168.3.201:7012/',
  // chatsServerUrl: 'https://qyi.careate.cn/',

  //正式环境
  authServerUrl:"https://qyi.careate.cn/",
  appServerUrl: 'https://qyi.careate.cn/',
  fileServerUrl: 'https://qyi.careate.cn/',
  ossServerUrl: 'http://careate.vicp.net:8012/',
  chatsServerUrl: 'https://qyi.careate.cn/',

  // authServerUrl: "https://192.168.3.201/",
  // appServerUrl: 'https://192.168.3.201:7012/',
  // fileServerUrl: 'https://192.168.3.201:7199/',
  // ossServerUrl: 'https://192.168.3.201:7012/',
  // chatsServerUrl: 'https://qyi.careate.cn/',

  requestTimeout: 20000,
  publicKey: "",

  sha1key: "l6uQkhCS",
  aeskey: "d1uQkhC@D62d4hCS",
  appsource: "WEB",
  clientid: "93ca23b5efb59d9f423a98ec2188b5d8",
  nativeversion: "2.2.2",

  // sha1key: 'l621jsHG',
  // aeskey:'0965khC@J1riehAA',
  // appsource:'TEST',
  // clientid:'1sdr23ddwrb59d9f423a98iire88b4h5',
  // nativeversion:'2.2.2'
}

export const prodServers = {
  authServerUrl: "https://qyi.careate.cn/",
  appServerUrl: "https://qyi.careate.cn/",
  fileServerUrl: "",
  requestTimeout: 20000,
  publicKey: "",
  sha1key: "",
  aeskey: "",
  appsource: "",
  clientid: "",
  nativeversion: "",
}
