import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AlertController, NavController } from '@ionic/angular';
// import { EventEmitter } from 'events';
import { BasePage } from 'src/app/common/basepage';
import { BindPatientModel } from 'src/app/model/BindPatientModel';
import { RemarkItemsModel } from 'src/app/model/RemarkItemsModel';
import { ServiceUserInfoModel } from 'src/app/model/ServiceUserInfoModel';
import { Helper } from 'src/app/providers/Helper';
import { HttpService } from 'src/app/providers/HttpService';
import { StorageManage } from 'src/app/providers/StorageManage';
import { Utils } from 'src/app/providers/Utils';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { SearchSelectComponent } from '../search-select/search-select.component';
declare var $: any;
@Component({
  selector: 'app-service400',
  templateUrl: './service400.component.html',
  styleUrls: ['./service400.component.scss'],
})
export class Service400Component extends BasePage {
  showCloudsImageRefundPopView:boolean = false;
  type: string = ''
  cloudsImageRefundReason:string = "";
  //1问题类别
  //2问题分类
  //3问诊方式
  //4所属系统
  //5所属医院
  @Input()
  userPhone: string;
  @Input()
  BusID: string;
  @Input()
  CSID: string;

  @Output() addRecord: EventEmitter<any> = new EventEmitter();

  @ViewChild(SearchSelectComponent) SearchSelect: SearchSelectComponent

  editRemark: string = "";
  bindPatientList: BindPatientModel[] = [];
  service400HistoryList: any[] = [];
  serviceUserInfo: ServiceUserInfoModel = new ServiceUserInfoModel();
  hasBindPatientInfo: boolean = false;
  hasHistory: boolean = false;
  isClosed: boolean = false;
  isPopup: boolean = false;
  remarksHospital: string = '';
  remarkCategory: string = '';
  remarkType: string = '';
  consultType: string = '';
  phoneSource: string = '';
  solution: string = '';
  remark: string = '';
  addTime: string = '';

  constructor(public nav: NavController,
    public httpService: HttpService,
    public alertCtr: AlertController,
    public re: ChangeDetectorRef,
    public utils: Utils, public storage: StorageManage, public helper: Helper, public route: ActivatedRoute) {
    super(utils, nav, route)
  }


  _ngOnInit() {
    //获取400备注选项
    this.getRemarksItems();
    this.getRemarksHospital();
    // this.getCloudsImageList();
  }

  getServiceUserInfo(userPhoneNumber, CID, IsClose: string) {
    console.log("====================");
    //页码归位
    this.currentCloudsImagePageIndex = 1;
    this.currentHistoryPageIndex = 1;
    this.isClosed = IsClose == "0" ? false : true;
    console.log(this.isClosed);
    this.httpService
      .toInteligentServicePost("api/BusinessService/GetUserCustomByPhone", {
        Phone: userPhoneNumber,
        cid: CID ? CID : '',
      }).subscribe(
        result => {
          console.log("关联就诊人信息111---",result);
          console.log(result);
          if (result.errorcode == "00000000") {//成功
            this.serviceUserInfo = result.data.customModel;
            console.log('this.serviceUserInfo---', this.serviceUserInfo)
            if (this.serviceUserInfo) {
              this.serviceUserInfo.RegDate = this.serviceUserInfo.RegDate.replace("T", " ");
            }

            this.bindPatientList = result.data.patientModel;
            if (result.data.patientModel.length > 0) {
              this.hasBindPatientInfo = true;
            }
          }
        },
        (error: HttpErrorResponse) => {
          console.log(error);
        }
      )
  }

  //新增备注
  addRemarks() {

    if (!this.RecordCategory) {
      this.helper.toast("问题类别不能为空！");
      return;
    } else if (!this.RecordType) {
      this.helper.toast("问题分类不能为空！");
      return;
    } else if (!this.RecordSeekMedical) {
      this.helper.toast("问诊方式不能为空！");
      return;
    } else if (!this.RecordOS) {
      this.helper.toast("所属系统不能为空！");
      return;
    } else if (!this.RecordHospital) {
      this.helper.toast("所属医院不能为空！");
      return;
    } else if (this.recordDiscription.length == 0) {
      this.helper.toast("问题描述不能为空！");
      return;
    } else if (this.recordSolution.length == 0) {
      this.helper.toast("解决方案不能为空！");
      return;
    } else if (this.recordDiscription.length > 300) {
      this.helper.toast("问题描述不能多于300字！");
      return;
    } else if (this.recordSolution.length == 0) {
      this.helper.toast("解决方案不能多于300字！");
      return;
    }

    this.addRecord.emit({
      "RecordCategory": this.RecordCategory,
      "RecordSeekMedical": this.RecordSeekMedical,
      "RecordStatus": this.RecordStatus,
      "RecordOS": this.RecordOS,
      "RecordType": this.RecordType,
      "RecordHospital": this.RecordHospital,
      "recordDiscription": this.recordDiscription,
      "recordSolution": this.recordSolution
    });
  }

  RecordCategory: any;
  RecordSeekMedical: any;
  RecordStatus: any;
  RecordOS: any;
  RecordType: any;
  RecordHospital: any;
  recordDiscription: string = "";
  recordSolution: string = "";

  changeRecordCategory(event) {
    console.log(event)
    this.RecordCategory = event.target.value;
    console.log(this.RecordCategory);
  }

  changeRecordSeekMedical(event) {
    this.RecordSeekMedical = event.target.value;
  }

  changeRecordOS(event) {
    this.RecordOS = event.target.value;
  }

  changeRecordType(event) {
    console.log(event)
    this.RecordType = event.target.value;
  }

  changeRecordHospital(event) {
    this.RecordHospital = event.target.value;
    console.log(this.RecordHospital);
  }

  changeRecordStatus(event) {
    this.RecordStatus = event.target.value;
  }


  RemarkCategoryItems: RemarkItemsModel[] = [];
  RemarkTypeItems: RemarkItemsModel[] = [];
  RemarkSeekMedicalItems: RemarkItemsModel[] = [];
  RemarkOSItems: RemarkItemsModel[] = [];

  getRemarksItems() {
    if(localStorage.getItem('GetSysCode')){
      if(JSON.parse(localStorage.getItem('GetSysCode')).expires>new Date().getTime()){
        console.log('未超时')
        this.handleResultGetSysCode(JSON.parse(localStorage.getItem('GetSysCode')).value)
      }else{
        this.GetSysCode()
      }
    }else{
      this.GetSysCode()
    } 
  }
  GetSysCode(){
    this.httpService.toInteligentServicePost('api/BusinessService/GetSysCode', {
    }).subscribe(
      result => {
        let t = new Date().getTime();
          let t1 = t+ (3600 * 1000 * 2)
          let obj = {
            value: result,
            expires: t1
          }
          localStorage.setItem('GetSysCode',JSON.stringify(obj))
        this.handleResultGetSysCode(result)
      },
      (error: HttpErrorResponse) => {
        console.log(error);
      }
    )
  }
  handleResultGetSysCode(result){
    console.log(result);
        if (result.errorcode == "00000000") {
          let patientNeedsItems = [];
          result.data.forEach(element => {
            if (element.CodeType == "142") {
              // 患者需求 // 业务类型
              patientNeedsItems.push(element)
            }if (element.CodeType == '121') {
              this.RemarkCategoryItems.push(element);
            } else if (element.CodeType == '124') {
              this.RemarkTypeItems.push(element);
            } else if (element.CodeType == '122') {
              this.RemarkSeekMedicalItems.push(element);
            } else if (element.CodeType == '123') {
              this.RemarkOSItems.push(element);
            }
          });
          localStorage.setItem("patientNeedsItems", JSON.stringify(patientNeedsItems));
          var that = this
          setTimeout(() => {
            $(".compayId").searchableSelect();
            for (let i = 0; i < 5; i++) {
              console.log(i)
              $(`#compayId${i}`).on("change", function (e) {
                if (e.target.value) {
                  // that.RecordType =that.RemarkTypeItems[e.target.value].Meaning;
                  that.matching(0, i, e.target.value)
                } else {
                  that.matching(0, i, '')
                }
              });
            }

          }, 500);
        }
        this.re.detectChanges()
  }
  matching(x, y, z) {//x:1弹窗，y:所选select,z:当前select 的value值
    console.log(x, y, z)
    if (z) {
      if (y == 0 || y == 5) {//问题类别
        if (x == 0) {
          this.RecordCategory = z;
          // this.RecordCategory =this.RemarkCategoryItems[z].Meaning;
        } else {//弹窗
          this.remarkCategory = z;
          // this.remarkCategory =this.RemarkCategoryItems[z].Code;
        }
      } else if (y == 1 || y == 6) {//问题分类
        if (x == 0) {
          this.RecordType = z;
          // this.RecordType =this.RemarkTypeItems[z].Meaning;
        } else {//弹窗
          this.remarkType = z;
          // this.remarkType =this.RemarkTypeItems[z].Code;
        }
      } else if (y == 2 || y == 7) {//问诊方式
        if (x == 0) {
          this.RecordSeekMedical = z;
          // this.RecordSeekMedical =this.RemarkSeekMedicalItems[z].Meaning;
        } else {//弹窗
          this.consultType = z;
          // this.consultType =this.RemarkSeekMedicalItems[z].Code;
        }
      } else if (y == 3 || y == 8) {//所属系统
        if (x == 0) {
          this.RecordOS = z;
          // this.RecordOS =this.RemarkOSItems[z].Meaning;
        } else {//弹窗
          // this.phoneSource =this.RemarkOSItems[z].Code;
          this.phoneSource = z;
        }
      } else if (y == 4 || y == 9) {//所属医院
        if (x == 0) {
          // this.RecordHospital =this.remarksHospitalItems.filter(t=>t.HID==z)[0].HospitalName;
          this.RecordHospital = z;
        } else {//弹窗
          // this.remarksHospital =this.remarksHospitalItems.filter(t=>t.HospitalName==z)[0].HID;
          this.remarksHospital = z;
        }
      }
    } else {
      if (y == 0 || y == 5) {//问题类别
        this.RecordCategory = '';
      } else if (y == 1 || y == 6) {//问题分类
        this.RecordType = '';
      } else if (y == 2 || y == 7) {//问诊方式
        this.RecordSeekMedical = '';
      } else if (y == 3 || y == 8) {//所属系统
        this.RecordOS = '';
      } else if (y == 4 || y == 9) {//所属医院
        this.RecordHospital = '';
      }
    }
  }
  remarksHospitalItems: any[] = [];
  getRemarksHospital() {
    this.helper.showLoading()
    this.httpService.toInteligentServicePost('api/BusinessService/Get400CacheAllHospital', {
    }).subscribe(
      result => {
        this.helper.hideLoading()
        console.log(result);
        if (result.errorcode == "00000000") {
          this.remarksHospitalItems = result.data;
        }
      },
      (error: HttpErrorResponse) => {
        console.log(error);
      }
    )

  }



  currentHistoryPageIndex: number = 1;
  historyTotalSize: number = 0;
  historyPerPageSize: number = 3;
  get400HistoryList(userPhoneNumber, addtime) {
    this.userPhone = userPhoneNumber;
    this.addTime = addtime.replace("T", " ").substr(0, 16);
    this.httpService
      .toInteligentServicePost("api/BusinessService/Get400HistoryList", {
        // CSID:this.CSID,
        Phone: userPhoneNumber,
        PageIndex: this.currentHistoryPageIndex,
        PageSize: this.historyPerPageSize
      }).subscribe(
        result => {
          console.log("====================");
          console.log(result);
          if (result.errorcode == "00000000") {//成功
            console.log(result.data.Rows)
            this.service400HistoryList = result.data.Rows;
            this.service400HistoryList.forEach(element => {
              element.AddTime = element.AddTime.replace("T", " ").replace(/-/g, "/");
            });
            this.historyTotalSize = result.data.Total;
            if (result.data.Total > 0) {
              if (this.currentHistoryPageIndex == 1) {
                this.judgeCanUpOrDown(1);
              }

              this.hasHistory = true;
              setTimeout(() => {
                this.addVideoControl();
              }, 100);
            } else {
              this.hasHistory = false;

            }
          }
        },
        (error: HttpErrorResponse) => {
          console.log(error);
        }
      )
  }

  cloudsImageList: any[] = [];
  currentCloudsImagePageIndex: number = 1;
  userPID: string;
  hasCloudsImage: boolean = false;
  imagesTotalSize: number;
  getCloudsImageList(PID) {
    this.userPID = PID;
    this.httpService
      .toInteligentServicePost("api/BusinessService/GetHYPacsList", {
        PID: this.userPID,
        PageIndex: this.currentCloudsImagePageIndex,
        PageSize: "3"
      }).subscribe(
        result => {
          console.log("====================");
          console.log(result);
          if (result.errorcode == "00000000") {//成功
            console.log(result.data)
            this.cloudsImageList = result.data.Rows;
            this.cloudsImageList.forEach(element => {
              if (element.HYMemberID) {
                element.ServiceStartTime = element.ServiceStartTime.replace("T", " ").replace(/-/g, "/");
                element.ServiceEndTime = element.ServiceEndTime.replace("T", " ").replace(/-/g, "/");
              }
              element.ImageTime = element.ImageTime.replace("T", " ").replace(/-/g, "/");
            });
            this.imagesTotalSize = result.data.Total;
            if (result.data.Rows.length > 0) {
              if (this.currentCloudsImagePageIndex == 1) {
                this.judgeCanUpOrDown(2);
              }

              this.hasCloudsImage = true;
            } else {
              this.hasCloudsImage = false;
            }
          }
        },
        (error: HttpErrorResponse) => {
          console.log(error);
        }
      )
  }

  canPageDown: boolean = true;
  canPageUp: boolean = false;

  canImageDown: boolean = true;
  canImageUp: boolean = false;

  pageChangeButtonTapped(changeType, pageType) {
    if (pageType == 1) {
      if (changeType == 1) {//上一页
        if (this.currentHistoryPageIndex != 1) {
          this.currentHistoryPageIndex--;
        }
      } else {//下一页
        if (this.currentHistoryPageIndex * this.historyPerPageSize < this.historyTotalSize) {
          this.currentHistoryPageIndex++;
        }
      }
      this.get400HistoryList(this.userPhone, this.addTime);
    } else {
      if (changeType == 1) {//上一页
        if (this.currentCloudsImagePageIndex != 1) {
          this.currentCloudsImagePageIndex--;
        }
      } else {//下一页
        if (this.currentCloudsImagePageIndex * 3 < this.imagesTotalSize) {
          this.currentCloudsImagePageIndex++;
        }
      }
      this.getCloudsImageList(this.userPID);
    }


    this.judgeCanUpOrDown(pageType);

  }

  //pageType：1历史记录 2.影像
  judgeCanUpOrDown(pageType) {
    if (pageType == 1) {
      if (this.currentHistoryPageIndex == 1) {
        this.canPageUp = false;
        if (this.currentHistoryPageIndex * this.historyPerPageSize < this.historyTotalSize) {
          this.canPageDown = true;
        } else {
          this.canPageDown = false;
        }
      } else {
        this.canPageUp = true;
        if (this.currentHistoryPageIndex * this.historyPerPageSize >= this.historyTotalSize) {
          this.canPageDown = false;
        } else {
          this.canPageDown = true;
        }
      }
    } else {
      if (this.currentCloudsImagePageIndex == 1) {
        this.canImageUp = false;
        if (this.currentCloudsImagePageIndex * 3 < this.imagesTotalSize) {
          this.canImageDown = true;
        } else {
          this.canImageDown = false;
        }
      } else {
        this.canImageUp = true;
        if (this.currentCloudsImagePageIndex * 3 >= this.imagesTotalSize) {
          this.canImageDown = false;
        } else {
          this.canImageDown = true;
        }
      }
    }

  }

  addVideoControl() {
    var videos = document.getElementsByTagName('video');//获取所有video
    //循环给所有video添加监听事件 当前的video开始播时  调用pauseAll 将当前播放的video的索引传值过去
    for (var i = videos.length - 1; i >= 0; i--) {
      (function (n) {
        videos[n].addEventListener('play', function () {
          pauseAll(n);
        })
      })(i)
    }
    //接收调用传来的索引 循环所有video 索引与传来的索引不相同的 暂停 重载
    function pauseAll(index) {
      for (var j = videos.length - 1; j >= 0; j--) {
        if (j != index) {
          videos[j].pause();
          videos[j].load();
        }
      }
    };
  }

  //停掉所有的音频播放
  pauseAllRecordAudio() {
    var videos = document.getElementsByTagName('video');//获取所有video
    //  调用pauseAll 将当前播放的video的索引传值过去
    for (var i = videos.length - 1; i >= 0; i--) {
      (function (n) {
        pauseAll(n);
      })(i)
    }
    //接收调用传来的索引 循环所有video 索引与传来的索引不相同的 暂停 重载
    function pauseAll(index) {
      for (var j = videos.length - 1; j >= 0; j--) {
        if (j != index) {
          videos[j].pause();
          videos[j].load();
        }
      }
    };
  }

  serviceAudioClick(item) {
    console.log(item);
  }
  // ts
  getValue(value) {
    console.log(value)
    this.editRemark = value;
  }
  editClick(list) {
    console.log(list)
    this.BusID = list.BusID;
    this.remarksHospitalItems.forEach(item => {
      if (item.HospitalName == list.HospitalName) {
        this.remarksHospital = item.HID;
      }
    });
    this.RemarkCategoryItems.forEach(item => {
      if (item.Meaning == list.QuesCategory) {
        this.remarkCategory = item.Code;
      }
    })
    this.RemarkTypeItems.forEach(item => {
      if (item.Meaning == list.QuesClassify) {
        this.remarkType = item.Code;
      }
    })
    this.RemarkSeekMedicalItems.forEach(item => {
      if (item.Meaning == list.ConsultType) {
        this.consultType = item.Code;
      }
    })
    this.RemarkOSItems.forEach(item => {
      if (item.Meaning == list.PhoneSource) {
        this.phoneSource = item.Code;
      }
    })
    this.isPopup = true;
    this.remark = list.Remark;
    this.solution = list.Solution
    var that = this
    setTimeout(() => {
      $(".compayId1").searchableSelect();
      for (let i = 5; i < 10; i++) {
        $(`#compayId${i}`).on("change", function (e) {
          if (e.target.value) {
            that.matching(1, i, e.target.value)
          } else {
            that.matching(1, i, '')
          }
        });
      }
    });
  }
  updateBusinessService() {
    this.httpService.toInteligentServicePost('api/BusinessService/UpdateBusinessService', {
      BusID: this.BusID,
      Remark: this.remark,
      QuesCategory: this.remarkCategory,
      QuesClassify: this.remarkType,
      ConsultType: this.consultType,
      PhoneSource: this.phoneSource,
      Solution: this.solution,
      HID: this.remarksHospital,
    }).subscribe(result => {
      if (result.errorcode == '00000000') {
        this.isPopup = false;
        this.get400HistoryList(this.userPhone, this.addTime);
      }
    })
  }

  isCloudsImagePopup: boolean = false;
  cloudsImageDetail: any;
  cloudsImageDetailClick(item) {
    this.isCloudsImagePopup = true;
    this.cloudsImageDetail = item;
  }

  //云影像退费
  RefundBtnTapped(item) {
    this.showCloudsImageRefundPopView = true;
    this.cloudsImageDetail = item;
    this.cloudsImageRefundReason = "";
  }

  cloudsImageRefundTapped() {
    if (this.cloudsImageRefundReason.length == 0) {
      this.helper.toast("请填写退费理由");
      return;
    }

    this.helper.showLoading();
    this.httpService
    .toInteligentServicePost("api/BusinessService/HYRefund", {
      OperName: this.BusID,
      BussNo: this.cloudsImageDetail.HYMemberID,
      ReturnReason: this.cloudsImageRefundReason
    }).subscribe(
      result => {
        console.log("====================");
        this.helper.hideLoading();
        console.log(result);
        if (result.errorcode == "00000000") {//成功
          this.showCloudsImageRefundPopView = false;
          this.isCloudsImagePopup = false;
          this.cloudsImageRefundReason = "";
          this.helper.toast(result.message,1000);
          this.getCloudsImageList(this.userPID);
        }else{
          this.helper.toast(result.message,1000);
        }
      },
      (error: HttpErrorResponse) => {
        console.log(error);
      }
    )
  }

}
