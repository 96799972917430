import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';
import { GlobalData } from './providers/GlobalData';
import { NativeService } from './providers/NativeService';
import { SettingsProvider } from './providers/theme.providers';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  selectedTheme: String;
  config: { version: string; };
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    public router: Router,
    public native: NativeService,
    private statusBar: StatusBar,
    private settings: SettingsProvider,
    private httpClient: HttpClient
  ) {
    this.initializeApp();
    this.platform.backButton.subscribe(() => {
      if (this.router.url === '/login' && !GlobalData.userInfo) {
        this.native.appMinimize();
      }
    });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.settings.getActiveTheme().subscribe(val => {
        console.log('theme = ' + val);
        const plats: any = this.platform.platforms();
        if (plats.length > 0) {
          console.log('platform = ' + plats[0]);
          // 判断当前平台是android平台核实ios平台
          if (plats[0] === 'android') {
            this.selectedTheme = val + ' md ion-page hydrated';
          } else {
            this.selectedTheme = val + ' ios ion-page hydrated';
          }
        } else {
          this.selectedTheme = val;
        }

      })
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  ngOnInit() {
    this.config = require('./../assets/config.json'); // 读取配置文件
    const headers = new HttpHeaders()
      .set('Cache-Control', 'no-cache')
      .set('Pragma', 'no-cache');
    this.httpClient.get<{ version: string }>('/assets/config.json', { headers })
      .subscribe(result => {
        console.log(result);
        if (result.version !== this.config.version) {
          this.config.version = result.version;
          setTimeout(() => {
            window.location.replace(window.location.href); // 重新加载应用
          }, 1000);
        }
      });
  }
}
