import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BasePage } from 'src/app/common/basepage';
import { RemarkItemsModel } from 'src/app/model/RemarkItemsModel';
import { NavController, AlertController } from '@ionic/angular';
import { HttpService } from 'src/app/providers/HttpService';
import { Utils } from 'src/app/providers/Utils';
import { StorageManage } from 'src/app/providers/StorageManage';
import { Helper } from 'src/app/providers/Helper';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-search-select',
    templateUrl: './search-select.component.html',
    styleUrls: ['./search-select.component.scss'],
})
export class SearchSelectComponent extends BasePage {

    //-------页面Model------
    searchModel: string = ''
    @Input()
    type:string=''
    @Input()
    selectModel: RemarkItemsModel[]
    @Output()
    CallBack: EventEmitter<string> = new EventEmitter();
    constructor(public nav: NavController,
        public service: HttpService, public alertCtr: AlertController,
        public utils: Utils, public storage: StorageManage, public helper: Helper, public route: ActivatedRoute) {
        super(utils, nav, route)
    }

    //-----初始-----
    _ngOnInit() {
        // console.log("=================" + this.controlType);
        console.log(this.type)
        console.log(this.selectModel)

    }
    search(e) {
        console.log('keypress')
    }
    clearEvent(e) {
        console.log('clearEvent')
    }
    onSearchChange(e) {
        console.log('onSearchChange')
    }
    onCancel(e) {
        console.log('onCancel')

    }
    selected(item) {
        item.type=this.type;
        let data = JSON.stringify(item)
        this.CallBack.emit(data);
    }
    stopPropagation(e) {
        e.stopPropagation();
    }

}
