// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { devServers } from './environment.conf';
export const environment = {
  production: false,
  authServerUrl: devServers.authServerUrl,
  appServerUrl: devServers.appServerUrl,
  fileServerUrl: devServers.fileServerUrl,
  ossServerUrl: devServers.ossServerUrl,
  chatsServerUrl:devServers.chatsServerUrl,
  requestTimeout: devServers.requestTimeout,
  sha1key: devServers.sha1key,
  aeskey: devServers.aeskey,
  appsource: devServers.appsource,
  clientid: devServers.clientid,
  nativeversion: devServers.nativeversion,
  publicKey: devServers.publicKey
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
