export class RemarkItemsModel {
    Code?: string
    CodeOrder?: string
    CodeType?: string
    IsEnabled?: string
    Meaning: string
    ParentCode?: string
    Remark?:string
    SpellCode?: string
}
export class RelateOrderModel {
    WorkType: number
    PID: string
    RegCode: string
    DocID: string
    DeptID: string 
    HID: string
}