import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Helper } from './Helper';
import { Utils } from './Utils';
import { GlobalData } from './GlobalData';
/**
 * 缓存工具类
 * 注：代码中务必使用此方法缓存数据，方便以后切换缓存机制
 */
@Injectable({
    providedIn: 'root'
})
export class StorageManage {

    constructor(
        private storage: Storage,private helper:Helper) {
    }
    public static sessionStorage = {
        set(key: string, value: any) {
            sessionStorage.setItem(key, JSON.stringify(value));
        },
        get(key: string) {
            const jsonString = sessionStorage.getItem(key);
            return jsonString ? JSON.parse(jsonString) : null;
        },
        remove(key: string) {
            sessionStorage.removeItem(key);
        },
        clear() {
            sessionStorage.clear();
        }
    };

    public  localStorage = {
        set(key: string, value: any) {
            localStorage.setItem(key, JSON.stringify(value));
        },
        get(key: string) {
            const jsonString = localStorage.getItem(key);
            return jsonString ? JSON.parse(jsonString) : null;
        },
        remove(key: string) {
            localStorage.removeItem(key);
        },
        clear() {
            localStorage.clear();
        }
    };
//数据缓存到本地sqlite
    isOvertimeDataSqlite(key): Promise<any> {
        try {
            return this.getAppStorage(key).then(result => {
                if (result != null && result.overtime > new Date().getTime() && result.ver == GlobalData.appver)//是否超时
                {
                    if (result.data == null || result.data == "" || result.data.length <= 0)//有可能是超时所致的无数据，无数据时还要继续获取数据
                        return null
                    else
                        return result;//不超时
                }
                else
                    return null//超时
            }).catch(error => {
                return null
            })
        } catch (e) {
            this.helper.toast(e)
            return null
        }
    }
    //存储本地存储
    setAppStorage(key, value) {
        return this.storage.remove(key).then(() => {
            return this.storage.set(key, value).then((val) => {
                return val
            })
        });
    }
    //获取本地存储
    getAppStorage(key) {
        return this.storage.get(key).then((val) => {
           return  val
            
        })
    }

    //删除本地存储
    delAppStorage(key) {
        return this.storage.remove(key).then((val) => {
            console.log(val);
            return val
        })
    }
    //清除本地存储
    clearAppStorage() {
        return this.storage.clear().then((val) => {
            return val
        })
    }
    delSqlite(key) {
        return this.storage.remove(key)
    }
    //保存到Sqlite分钟
    saveSqlite(key, val, minutes) {
        var date = JSON.parse("{}");
        date.data = val;
        date.ver = GlobalData.appver;
        date.overtime = new Date(this.dateAdd("mm", minutes)).getTime()
        //console.log(date);
        return this.storage.remove(key).then(() => {
            return this.storage.set(key, date).then(() => {
            })
        });
    }
    //获取Sqlite
    getSqlite(key): Promise<any> {
        return this.storage.get(key)
            .then(
                data => {
                    if (data != null) {
                        return data
                    }
                    else {
                        return null
                    }
                },
                error => {
                    //console.log("同步失败：" + error)
                    return Promise.resolve()
                });
    }
    //获取Storage
    getStorage(key) {
        //如果全局有值就取全局
        if (GlobalData[key] && GlobalData[key].length>0) {
            return GlobalData[key];
        }
        else {
            return localStorage.getItem(key)
            // return   this.getAppStorage(key).then(val=>{
            //      console.log(val)
            //     return  val ? JSON.parse(val) : null;
            //     });
        }
    }
    setStorage(key, value) {

        if (GlobalData[key]) {
            GlobalData[key] = value
            // this.setAppStorage(GlobalData[key],value)
        } 
            localStorage.setItem(key, value)
            // this.setAppStorage(key, value)
    }
    deleteStorage(key){
        if (GlobalData[key]) {
            GlobalData[key] = null
            // this.setAppStorage(GlobalData[key],value)
        } 

        this.localStorage.remove(key);
    }
    /*
   *   功能:实现VBScript的DateAdd功能.
   *   参数:interval,字符串表达式，表示要添加的时间间隔.
   *   参数:number,数值表达式，表示要添加的时间间隔的个数.
   *   参数:date,时间对象.
   *   返回:新的时间对象.
   *   var now = new Date();
   *   var newDate = DateAdd( "d", 5, now);
   *---------------   DateAdd(interval,number,date)   -----------------
   */
   dateAdd(interval, number, date?) {
    if (date == null)
        date = new Date()
    switch (interval) {
        case "y": {
            date.setFullYear(date.getFullYear() + number);
            break;
        }
        case "q": {
            date.setMonth(date.getMonth() + number * 3);
            break;
        }
        case "m": {
            date.setMonth(date.getMonth() + number);
            break;
        }
        case "w": {
            date.setDate(date.getDate() + number * 7);
            break;
        }
        case "d": {
            date.setDate(date.getDate() + number);
            break;
        }
        case "h": {
            date.setHours(date.getHours() + number);
            break;
        }
        case "mm": {
            date.setMinutes(date.getMinutes() + number);
            break;
        }
        case "s": {
            date.setSeconds(date.getSeconds() + number);
            break;
        }
        default: {
            date.setDate(date.getDate() + number);
            break;
        }
    }
    return date.getFullYear() + "/" + this.GenerateID((date.getMonth() + 1)) + "/" + this.GenerateID(date.getDate()) + " " + this.GenerateID(date.getHours()) + ":" + this.GenerateID(date.getMinutes()) + ":" + this.GenerateID(date.getSeconds());
}
     GenerateID(str: any) {
        var pad = "00"
        return pad.substring(0, pad.length - (str + "").length) + str
    }
     setHospitalStorage(hid: string) {
        var sort =  this.getStorage("HospitalSort");
        var sortArray = [];
        var endSortArray = [];
        if (sort) {
            sortArray = JSON.parse( sort);
            sortArray.reverse();
        }
        sortArray.forEach((item) => {
            if (item != hid) {
                endSortArray.push(item);
            }
        });
        endSortArray.push(hid);
        endSortArray.reverse();
        this.setStorage("HospitalSort", JSON.stringify(endSortArray));
    }
}
