import { GlobalData } from 'src/app/providers/GlobalData';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StorageManage } from './StorageManage';
import { environment } from 'src/environments/environment';
import { NgForm } from '@angular/forms';

/**
 * 工具类：存放和业务无关的公共方法
 */
@Injectable({
    providedIn: 'root'
})
export class Utils {
    constructor(
        private storage: StorageManage) {
    }
    /**
     * 日期对象转为日期字符串
     * @param date 需要格式化的日期对象
     * @param sFormat 输出格式,默认为yyyy-MM-dd                        年：y，月：M，日：d，时：h，分：m，秒：s
     * @example  dateFormat(new Date())                               "2017-02-28"
     * @example  dateFormat(new Date(),'yyyy-MM-dd')                  "2017-02-28"
     * @example  dateFormat(new Date(),'yyyy-MM-dd HH:mm:ss')         "2017-02-28 13:24:00"   ps:HH:24小时制
     * @example  dateFormat(new Date(),'yyyy-MM-dd hh:mm:ss')         "2017-02-28 01:24:00"   ps:hh:12小时制
     * @example  dateFormat(new Date(),'hh:mm')                       "09:24"
     * @example  dateFormat(new Date(),'yyyy-MM-ddTHH:mm:ss+08:00')   "2017-02-28T13:24:00+08:00"
     * @example  dateFormat(new Date('2017-02-28 13:24:00'),'yyyy-MM-ddTHH:mm:ss+08:00')   "2017-02-28T13:24:00+08:00"
     */
    static dateFormat(date: Date, sFormat: String = 'yyyy-MM-dd'): string {
        const time = {
            Year: 0,
            TYear: '0',
            Month: 0,
            TMonth: '0',
            Day: 0,
            TDay: '0',
            Hour: 0,
            THour: '0',
            hour: 0,
            Thour: '0',
            Minute: 0,
            TMinute: '0',
            Second: 0,
            TSecond: '0',
            Millisecond: 0
        };
        time.Year = date.getFullYear();
        time.TYear = String(time.Year).substr(2);
        time.Month = date.getMonth() + 1;
        time.TMonth = time.Month < 10 ? '0' + time.Month : String(time.Month);
        time.Day = date.getDate();
        time.TDay = time.Day < 10 ? '0' + time.Day : String(time.Day);
        time.Hour = date.getHours();
        time.THour = time.Hour < 10 ? '0' + time.Hour : String(time.Hour);
        time.hour = time.Hour < 13 ? time.Hour : time.Hour - 12;
        time.Thour = time.hour < 10 ? '0' + time.hour : String(time.hour);
        time.Minute = date.getMinutes();
        time.TMinute = time.Minute < 10 ? '0' + time.Minute : String(time.Minute);
        time.Second = date.getSeconds();
        time.TSecond = time.Second < 10 ? '0' + time.Second : String(time.Second);
        time.Millisecond = date.getMilliseconds();

        return sFormat.replace(/yyyy/ig, String(time.Year))
            .replace(/yyy/ig, String(time.Year))
            .replace(/yy/ig, time.TYear)
            .replace(/y/ig, time.TYear)
            .replace(/MM/g, time.TMonth)
            .replace(/M/g, String(time.Month))
            .replace(/dd/ig, time.TDay)
            .replace(/d/ig, String(time.Day))
            .replace(/HH/g, time.THour)
            .replace(/H/g, String(time.Hour))
            .replace(/hh/g, time.Thour)
            .replace(/h/g, String(time.hour))
            .replace(/mm/g, time.TMinute)
            .replace(/m/g, String(time.Minute))
            .replace(/ss/ig, time.TSecond)
            .replace(/s/ig, String(time.Second))
            .replace(/fff/ig, String(time.Millisecond));
    }
    //带T时间格式转换为日期
    FormatDate(timevalue: string) {
        if (timevalue.lastIndexOf('T') != -1)
            return timevalue.substring(0, timevalue.indexOf("T"))
        if (timevalue.lastIndexOf(' ') != -1)
            return timevalue.substring(0, timevalue.indexOf(" "))
        return timevalue;
    }

    //Date 转成MM/dd
    changeToMMdd(date:Date){
        var Y = date.getFullYear();
        var M = date.getMonth() + 1;
        var D = date.getDate();
        var times = (M < 10 ? "0" : "") + M +(D < 10 ? "/0" : "/") + D;
        return times;
    }
    //日期转换成时间戳
    dateToTimeStamp(date:string){
        var dateString = date.replace("T"," ");
        dateString = dateString.replace(/-/g,'/');
        var formatdate = new Date(dateString);
        return formatdate.valueOf();
    }
    static getISO8601(isostr) {
        var parts = isostr.match(/\d+/g);
        return new Date(parts[0] + '-' + parts[1] + '-' + parts[2] + ' ' + parts[3] + ':' + parts[4] + ':' + parts[5]).getTime();
    }

    /**
     * 返回字符串长度，中文计数为2
     */
    static strLength(str: string): number {
        let len = 0;
        for (let i = 0, length = str.length; i < length; i++) {
            str.charCodeAt(i) > 255 ? len += 2 : len++;
        }
        return len;
    }

    /**
     * 密码强度 返回：low middle high
     */
    static checkPass(pwd) {
        let m = 0;
        if (pwd.length <= 6) {
            return 'low'; // 密码长度小于等于6
        }
        if (/\d/.test(pwd)) {
            m++; // 纯数字密码
        }
        if (/[a-z]/.test(pwd)) {
            m++; // 密码包含小写字母
        }
        if (/[A-Z]/.test(pwd)) {
            m++; // 密码包含大写字母
        }
        if (/\W/.test(pwd)) {
            m++; // 密码包含特殊字符
        }
        if (pwd.length > 15) {
            m = 4; // 密码长度大于15
        }
        if (m < 2) {
            return 'low';
        }
        if (m === 2) {
            return 'middle';
        }
        if (m >= 3) {
            return 'high';
        }
    }

    /**
     * 把url中的双斜杠替换为单斜杠
     * 如:http://localhost:8080//api//demo.替换后http://localhost:8080/api/demo
     */
    static formatUrl(url = ''): string {
        let index = 0;
        if (url.startsWith('http')) {
            index = 7;
        }
        return url.substring(0, index) + url.substring(index).replace(/\/\/*/g, '/');
    }

    /**
     * 产生一个随机的32位长度字符串
     */
    static uuid() {
        let text = '';
        const possible = 'abcdef0123456789';
        for (let i = 0; i < 19; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text + new Date().getTime();
    }

    /**
     * 根据图片路径把图片转为base64字符串格式
     */
    static convertImgToBase64(url: string, callback: Function, width: number = null, height: number = null, outputFormat = 'image/jpg') {
        const img = new Image();
        img.crossOrigin = 'anonymous';
        img.onload = function () {
            const canvas = <HTMLCanvasElement>document.createElement('canvas');
            canvas.width = width || img.width;
            canvas.height = height || img.height;
            const context = canvas.getContext('2d');
            context.drawImage(img, 0, 0, canvas.width, canvas.height);
            const imgBase64 = canvas.toDataURL(outputFormat);
            if (callback) {
                callback(imgBase64);
            }
        };
        img.src = url;
    }
    //保存Token超时时间到本地存储
    saveTokenToLocal(data): Observable<any> {
        console.log(data)
        return Observable.create(observer => {
            try {
                //本地存储Localstorage
                var t = new Date().getTime()
                t += parseInt(data.expires_in) * 1000//转毫秒
                GlobalData.userInfo = {
                    access_token: data.access_token,
                    refresh_token: data.refresh_token,
                    CID: data.userID,
                    OverTime: t.toString(),
                    //类里有的重新复制（因为在同步的过程中已经同步到数据了，而此操作是滞后的而不能不赋值所以要重新将类里有的值重新赋值）
                    hCID: data.hCID,
                    docOrgID: data.docOrgID,
                    rdid: data.rdid,
                    userType: data.userType,
                    hOrgID: data.hOrgID,
                    doctorName: data.doctorName
                }
                console.log(GlobalData.userInfo)
                // toApp(data.userName, "", "", data.cid, "", data.nickName);
                this.storage.setStorage("userInfo", JSON.stringify(GlobalData.userInfo));
                // this.storage.setAppStorage("userInfo",JSON.stringify(GlobalData.userInfo));
                observer.next(true);
            } catch (e) {
                observer.error(e)
            }
        })
    }

    syncData(): Promise<any> {
        var d = Utils.dateFormat(new Date(), "yyyyMMdd")

        if (!GlobalData.isUpdate || GlobalData.keyUpdateDate != d) {
            GlobalData.keyUpdateDate = d
            //console.log("+++++时间变更+++++"+d);
            GlobalData.appver = loadVer()
            // 用完后必须注释掉 
            this.storage.getAppStorage("NativeAppHeight")
                .then(
                    data => {
                        if (data != null) {
                            GlobalData.nativeAppHeight = data
                        }
                    });
            if (!environment.production)
                return this.storage.setAppStorage('emhlc2hpaXlnZW1peWFv', environment.sha1key + "|" + environment.aeskey + "|" + environment.appsource + "|" + environment.clientid + "|" + environment.nativeversion)
                    .then(data => {
                        try {
                            return this.storage.getAppStorage("emhlc2hpaXlnZW1peWFv").then(
                                data => {
                                    if (data != null && data != "") {
                                        var k = data.split('|')
                                        environment.sha1key = k[0]
                                        environment.aeskey = k[1]
                                        environment.appsource = k[2]
                                        if (k.length > 3)
                                            environment.clientid = k[3]
                                        if (k.length > 4)
                                            environment.nativeversion = k[4]

                                        return this.storage.getAppStorage("userInfo")
                                            .then(
                                                data => {
                                                    if (data != null) {
                                                        // console.log(data)
                                                        GlobalData.userInfo = data
                                                        //console.log("同步成功");
                                                        GlobalData.isUpdate = true
                                                    }
                                                    else {
                                                        //console.log("userinfo为空");
                                                    }
                                                },
                                                error => {
                                                    //console.log("同步失败：" + error)
                                                    return Promise.resolve()
                                                });
                                    }
                                    else {
                                        console.log("emhlc2hpaXlnZW1peWFv为空");
                                    }
                                },
                                error => {
                                    //console.log("同步失败：" + error)
                                    return Promise.resolve()
                                })
                        } catch (e) {
                            console.log(e)
                            return Promise.resolve()
                        }
                    })
        } else {
            GlobalData.isUpdate = true
            return Promise.resolve()
        }

    }
    formErrors: any = []
    //验证表单
    formChanged<T>(currentFrom: NgForm, T: any): any[] {

        var patientForm: NgForm;
        if (currentFrom === patientForm) { return; }
        patientForm = currentFrom;
        if (patientForm) {
            patientForm.valueChanges.subscribe(data => this.onValueChanged(data, patientForm, T))
        }
        return this.formErrors
    }

    onValueChanged<T>(data?: any, patientForm?: NgForm, T?: any): any[] {
        if (!patientForm) { return; }

        const form = patientForm.form;

        for (const field in T) {
            this.formErrors[field] = "";
            const control = form.get(field);
            if (control && control.dirty && !control.valid) {
                const message = T.validationMessages[field]

                for (const key in control.errors) {
                    this.formErrors[field] += message[key] + ' '
                    break;
                }
            }
        }
    }
    logoutCallback() {
        GlobalData.userInfo.CID = null;
        this.storage.delSqlite("userInfo")
        //调用原生清登录信息
        logoutCallback();
    }
    convertmoney(x) {
        var ff = parseFloat(x);
        if (isNaN(ff)) {
            return false;
        }
        var f = Math.round(x * 100) / 100;
        var s = f.toString();
        var rs = s.indexOf('.');
        if (rs < 0) {
            rs = s.length;
            s += '.';
        }
        while (s.length <= rs + 2) {
            s += '0';
        }
        return s;

    }

    //时间戳改几分钟前格式的字符串

    timestampFormat(timestamp) {
        function zeroize(num) {
            return (String(num).length == 1 ? '0' : '') + num;
        }

        if (!timestamp) {
            return;
        }
        console.log("timestamp==============>>>" + timestamp);

        timestamp = timestamp / 1000;

        var curTimestamp = new Date().getTime() / 1000; //当前时间戳
        var timestampDiff = curTimestamp - timestamp / 1000; // 参数时间戳与当前时间戳相差秒数

        console.log("curTimestamp==============>>>" + curTimestamp);
        console.log("timestamp==============>>>" + timestamp / 1000);
        console.log("timestampDiff==============>>>" + timestampDiff);

        var curDate = new Date(curTimestamp * 1000); // 当前时间日期对象
        var tmDate = new Date(timestamp * 1000);  // 参数时间戳转换成的日期对象

        var Y = tmDate.getFullYear(), m = tmDate.getMonth() + 1, d = tmDate.getDate();
        var H = tmDate.getHours(), i = tmDate.getMinutes(), s = tmDate.getSeconds();

        if (timestampDiff < 60) { // 一分钟以内
            return "刚刚";
        } else if (timestampDiff < 3600) { // 一小时前之内
            return Math.floor(timestampDiff / 60) + "分钟前";
        } else if (curDate.getFullYear() == Y && curDate.getMonth() + 1 == m && curDate.getDate() == d) {
            return '今天' + zeroize(H) + ':' + zeroize(i);
        } else {
            var newDate = new Date((curTimestamp - 86400) * 1000); // 参数中的时间戳加一天转换成的日期对象
            if (newDate.getFullYear() == Y && newDate.getMonth() + 1 == m && newDate.getDate() == d) {
                return '昨天' + zeroize(H) + ':' + zeroize(i);
            } else if (curDate.getFullYear() == Y) {
                // return  zeroize(m) + '月' + zeroize(d) + '日 ' + zeroize(H) + ':' + zeroize(i);
                return zeroize(m) + '月' + zeroize(d) + '日 ';
            } else {
                return Y + '年' + zeroize(m) + '月' + zeroize(d) + '日 ' + zeroize(H) + ':' + zeroize(i);
            }
        }
    }

    //获取本设备唯一ID
    getDeviceID() {
        var storageDeviceID = this.storage.getStorage("deviceID");
        if(!storageDeviceID){
            var tempGuid = this.guid();
            this.storage.setStorage("deviceID",tempGuid);
            storageDeviceID = tempGuid
        }
        console.log("storageDeviceID=============>>>"+storageDeviceID);
        return storageDeviceID;
    }


    S4() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    // Generate a pseudo-GUID by concatenating random hexadecimal. 
    guid() {
        return (this.S4() + this.S4() + "-" + this.S4() + "-" + this.S4() + "-" + this.S4() + "-" + this.S4() + this.S4() + this.S4());
    }

}
