import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes, NoPreloading } from '@angular/router';
const routes: Routes = [
 
  { path: 'login', loadChildren: './pages/login/login.module#LoginPageModule' },
  { path: 'qrscan', loadChildren: './pages/qrscan/qrscan.module#QrscanPageModule' },
  { path: 'nativeplugin', loadChildren: './pages/nativeplugin/nativeplugin.module#NativepluginPageModule' },
  
  { path: 'error', loadChildren: './pages/error/error.module#ErrorPageModule' },
  { path: 'address-list', loadChildren: './pages/address-list/address-list.module#AddressListPageModule' },
  { path: 'same-hospital', loadChildren: './pages/same-hospital/department.module#DepartmentPageModule' },
  { path:'person-detail', loadChildren: './pages//address-list/person-detail/person-detail.module#PersonDetailModule' },
  { path: 'office', loadChildren: './pages/address-list/office/office.module#OfficePageModule' },
  { path: 'remarks', loadChildren: './pages//new-friends/remarks/remarks.module#RemarksPageModule' },
  { path: 'new-friends', loadChildren: './pages/new-friends/new-friends.module#NewFriendsPageModule' },
  { path: 'add-verification', loadChildren: './pages/new-friends/add-verification/add-verification.module#AddVerificationPageModule' },
  { path: 'other-hospital', loadChildren: './pages/other-hospital/other-hospital.module#OtherHospitalPageModule' },
  { path: 'chat-group', loadChildren: './pages/chat-group/chat-group.module#ChatGroupPageModule' },
  { path: 'all-group', loadChildren: './pages/chat-group/all-group/all-group.module#AllGroupPageModule' },
  { path: 'search', loadChildren: './pages/search/search.module#SearchPageModule' },
  { path: 'blank', loadChildren: './pages/blank/blank.module#BlankPageModule' },
  { path: 'add-new-friend', loadChildren: './pages/new-friends/add-new-friend/add-new-friend.module#AddNewFriendPageModule' },
  { path: 'modify-groupname', loadChildren: './pages/chat-group/modify-groupname/modify-groupname.module#ModifyGroupnamePageModule' },
  { path: 'del-groupmember', loadChildren: './pages/chat-group/del-groupmember/del-groupmember.module#DelGroupmemberPageModule' },
  { path: 'group-setting', loadChildren: './pages/chat-group/group-setting/group-setting.module#GroupSettingPageModule' },
  { path: 'all-group-mumber', loadChildren: './pages/chat-group/all-group-mumber/all-group-mumber.module#AllGroupMumberPageModule' },
  { path: 'centresystem', loadChildren: './pages/centresystem/centresystem.module#CentresystemPageModule' },

  { path: '', redirectTo: 'centresystem', pathMatch: 'full' },
  { path: 'centresyslogin', loadChildren: './pages/centresyslogin/centresyslogin.module#CentresysloginPageModule' },











  










  











  










];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: NoPreloading }),

  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
