import { Injectable } from '@angular/core';
import { AlertController, LoadingController, Platform, ToastController } from '@ionic/angular';
import { Logger } from './Logger';
import { environment } from '../../environments/environment';
import { StorageManage } from './StorageManage';
import { GlobalData } from './GlobalData';
import * as moment from 'moment';

/**
 * 帮助类：存放和业务有关的公共方法
 */
@Injectable({
    providedIn: 'root'
})
export class Helper {
    readonly IsMobile: boolean = false;
    private AlertIsExist = false;
    private LoadingIsExist = false;
    private Loading = null;


    constructor(public platform: Platform,
        public alertController: AlertController,
        public loadingController: LoadingController,
        public toastController: ToastController) {
        this.IsMobile = this.platform.is('cordova');
    }


    /**
     * 是否真机环境
     */
    isMobile(): boolean {
        return this.IsMobile;
    }

    /**
     * 是否真机环境
     */
    isNotMobile(): boolean {
        return !this.isMobile();
    }


    /**
     * 是否android真机环境
     */
    isAndroid(): boolean {
        return this.isMobile() && this.platform.is('android');
    }

    /**
     * 是否ios真机环境
     */
    isIos(): boolean {
        return this.isMobile() && (this.platform.is('ios') || this.platform.is('ipad') || this.platform.is('iphone'));
    }


    /**
     * 断言是否真机环境
     */
    assertIsMobile(): void {
        if (this.isNotMobile()) {
            this.toast('请使用真机调试');
            throw new Error('请使用真机调试');
        }
    }

    /**
     * tip 开发中
     */
    tipDev() {
        this.toast('开发中');
    }

    alertWithTitle() {

    }

    /**
     * alert弹框，默认只有确定按钮，当存在取消回调函数则会显示取消按钮
     * 注：如果存在打开的alert则不再打开
     * @param header 需要显示的title
     * @param message 需要显示的内容
     * @param okBackFun 成功回调
     * @param cancelBtnFun 失败回调
     */
    alert(header = '', subHeader = '', message = '', okBtnText = "知道了", okBackFun = null, cancleBtnText = '取消', cancelBtnFun = null, secBtnText = '', secBtnFun = null): void {
        // alertController.create是异步方法，所以使用AlertIsExist标志是否打开
        if (this.AlertIsExist) {
            Logger.log('alert已经存在，禁止重复打开');
            setTimeout(() => { // alert关闭的可能性比较多，不止点击确定或取消按钮
                this.AlertIsExist = false;
            }, 1000);
            return;
        }
        this.AlertIsExist = true;
        const buttons = [];
        var isSecExe = false;
        if (okBackFun) {
            const okBtn = {
                text: okBtnText,
                handler: () => {
                    this.AlertIsExist = false;
                    okBackFun();
                }
            }
            buttons.push(okBtn);
        }

        if (cancelBtnFun) {
            const cancelBtn = {
                text: cancleBtnText,
                role: 'cancel',
                handler: () => {
                    this.AlertIsExist = false;
                    cancelBtnFun();
                }
            };
            buttons.unshift(cancelBtn);
        }
        if (secBtnFun) {
            const secBtn = {
                text: secBtnText,
                handler: () => {
                    this.AlertIsExist = false;
                    if (!isSecExe) {
                        secBtnFun && secBtnFun();
                        isSecExe = true;
                    }
                }
            }
            buttons.push(secBtn);
        }

        this.alertController.create({
            header,
            subHeader,
            message,
            buttons
        }).then(alert => alert.present());
    }
    /**
     * 异常信息提示
     * @param res 返回信息
     */
    errorAlert(res) {
        if (res.errorcode > 7000000 && res.errorcode <= 80000000)
            this.alert('App异常日志', res.message)
        else if (res.errorcode > 8000000 && res.errorcode <= 90000000)
            this.alert('用户异常日志', res.message)
        else if (res.errorcode >= 92000000)
            this.alert('用户异常日志', res.message)
        else if (res.errorcode < 1000)
            this.alert('用户异常日志', res.message)
    }
    /**
     * 显示提示信息
     * 建议优先调用 NativeService.toast
     */
    toast(message: string = '操作成功', duration: number = 2500, position: 'top' | 'bottom' | 'middle' = 'middle'): void {
        const opts = { message, duration, color: "dark", position, cssClass: "toast-success", showCloseButton: true, closeButtonText: '✖' };
        this.toastController.create(opts).then(toast => toast.present());
    }

    //cssClass:"background: #e0b500 width:60px text-align: center"
    /**
     * 统一调用此方法显示loading
     */
    showLoading(message: string = '',flag = false): void {
        if(flag) this.LoadingIsExist = false;
        if (this.LoadingIsExist && !flag) {
            return;
        }
        this.LoadingIsExist = true;
        this.loadingController.create({
            spinner: 'bubbles', // dots https://ionicframework.com/docs/api/spinner
            duration: 3000,
            message
        }).then(loading => {
            // loadingController.create异步方法，调用loading.present()前有可能已经调用hideLoading方法
            // if (this.LoadingIsExist  && flag) {
                this.Loading = loading;
                loading.present();
            // } else {
            //     loading.dismiss();
            // }
        });
    }

    /**
     * 关闭loading
     */
    hideLoading(): void {
        this.LoadingIsExist = false;
        setTimeout(() => {
            if (this.Loading) {
                this.Loading.dismiss();
                // this.Loading = null;
            }
        });
    }

    getQueryVariable(variable) {
        var query = window.location.href.split('?')[1];
        if (query && query.indexOf('&') > -1) {
            var vars = query.split("&");
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split("=");
                if (pair[0] == variable) {
                    return vars[i].slice(vars[i].indexOf('=') + 1);
                }
            }
            return (false);
        } else {
            return (false);
        }

    }

    logToLocalStorage(message: string): void {
        const logsKey = 'careate_logs';
        let logs: string[] = JSON.parse(localStorage.getItem(logsKey) || '[]');

        logs.push(message);
        localStorage.setItem(logsKey, JSON.stringify(logs));

        const logsSizeInBytes = new Blob([JSON.stringify(logs)]).size;
        const maxSizeInBytes = 20 * 1024 ; // 20k

        if (logsSizeInBytes > maxSizeInBytes) {
            this.downloadLogs(logs);
            // 清空本地日志
            localStorage.removeItem(logsKey);
        }
    }

    downloadLogs(logs: string[]): void {
        const logsBlob = new Blob([JSON.stringify(logs)], { type: 'text/plain' });
        const url = URL.createObjectURL(logsBlob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `careate_logs${moment().format('YYYYMMDD_HH:mm:ss')}.txt`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    }


    // 清除缓存的方法
    clearCache() {
        // 清除缓存的关键在于给每个请求添加一个唯一的query参数
        // const uniqueParam = 'noCache=' + new Date().getTime();

        // // 获取所有的style和link元素
        // const elements = document.getElementsByTagName('link');
        // for (let i = 0; i < elements.length; i++) {
        //     if (elements[i].rel === 'stylesheet') {
        //         elements[i].href += uniqueParam;
        //     }
        // }

        // // 获取所有的script元素
        // const scripts = document.getElementsByTagName('script');
        // for (let i = 0; i < scripts.length; i++) {
        //     scripts[i].src += uniqueParam;
        // }

        // localStorage.clear();
    }
}
