
import { Injectable } from '@angular/core';
import JsEncrypt from 'jsencrypt';
import { environment } from '../../environments/environment';
import * as CryptoJS from 'crypto-js';
import { GlobalData } from './GlobalData';
import { stringify } from 'querystring';

/**
* 简书文章：
* https://www.jianshu.com/p/b45d835b201b
*
* 加密工具类
* rsa非对称加密
*  针对敏感数据，前端使用此方法加密后传递给后端，后端使用私钥解密
*  公钥保存在前端，私钥保存在后端，不进行传输
*  @example
const publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCoYxMpKFXbyiehp9ktu+dDlcSp9UZnRFEdYiMPvqzrU6grokp/g3uijsXZN1cZbQc4LSSRvLnC/SIwZRxA2i5H9MQOBlM9qhBXFcAlinsfY3PE4LNlbYvLT5F9G/sbSP8VunzFnQdj6CPtLABnAinAtEZ8krNVPLpLGmzJfR/mzwIDAQAB';
const privateKey = 'MIICdQIBADANBgkqhkiG9w0BAQEFAASCAl8wggJbAgEAAoGBAKhjEykoVdvKJ6Gn2S2750OVxKn1RmdEUR1iIw++rOtTqCuiSn+De6KOxdk3VxltBzgtJJG8ucL9IjBlHEDaLkf0xA4GUz2qEFcVwCWKex9jc8Tgs2Vti8tPkX0b+xtI/xW6fMWdB2PoI+0sAGcCKcC0RnySs1U8uksabMl9H+bPAgMBAAECgYBlvvfk9qpqlEw+Md3Y9KFZBTZAPCS+YVliF9p3uQ9jYrlLJFU/l4MtRnfmOLo4ctjZ6O0f4pmcaLgv5eichzlO5JODg46LEB5VwGiZZVxN2Hh2FcTaQ7i0duwhYBD8bl7BLZI13Bu/Yv4MwjNBQr/r6++emvcr/036BKIwWaAkAQJBAOrrMANMKg/yHlKoL3zk7btCL0WVUHBZ/Sd3Ef1IlFI75Uvjr7iQHwm/XO9oAauoME0rV8kanE0xHHZ+15WpL6ECQQC3f3KZ7NAr5lRIo1PHHG5Xk9s6iuULI5T1uhFCkuYgrzqyHmPkjxaJzNOsnVachgU0+B1gDmK7ppnIDXXPXEBvAkAS7gq7aUrGaCs7W+Qfu07Q1R98CvElbIrywCyJ7WxOSBdNCzbgt3RY07vIaugfjfj+buyu/t7zdW6mucfjfnOhAkBP3KUY/us/H/iwwHzW3LXdYdl5KjgzV+Id7ERU0DBeK0WFfhqFwAzUHpRFvRiT+PRNMGtAgiJQf1rQqaMLg5/7AkAQixYTAfaXAE58wmIQnHXarQ9wvMQmq1cHbYXkyf3gMh/8DrURaS3sWpeY/N6qbqPCCET0q4n7RgVn4MyzC06N';
const str = '1234567890!@#$%^&*()这是将要加密的数据';
const rsaEncrypt = Encrypt.rsaEncrypt(str, publicKey);
console.log('rsa加密结果：', rsaEncrypt);
const rsaDecrypt = Encrypt.rsaDecrypt(rsaEncrypt, privateKey);
console.log('rsa解密结果：', rsaDecrypt);
*
*
* aes对称加密
*  生成一个key，可以同时用于加密和解密
*  主要用途：前端用rsa加密后传给后端，后端用key加密数据返回给前端，前端在用此key解密
*  @example
// const key = Utils.uuid().substr(0, 16); // 动态生成16位长度的key
const key = 'fa9353c6179dfbfa';
const aesEncrypt = Encrypt.aesEncrypt(str, key);
console.log('aes加密结果：', aesEncrypt);
const aesDecrypt = Encrypt.aesDecrypt(aesEncrypt, key);
console.log('aes解密结果：', aesDecrypt);
*
*/
@Injectable({
  providedIn: 'root'
})
export class Encrypt {
  private static jsEncrypt = null;

  /**
  * rsa加密
  */
  static rsaEncrypt(str: any, publicKey: string = environment.publicKey) {
    if (!this.jsEncrypt) {
      this.jsEncrypt = new JsEncrypt({});
    }
    if (typeof str !== 'string') {
      str = JSON.stringify(str);
    }
    this.jsEncrypt.setPublicKey(publicKey);
    // 由于rsa加密的数据可能有特殊字符，所以这里在用window.btoa进行base64编码，后端获取到数据需要先base64解码，然后再用私钥解密
    return window.btoa(this.jsEncrypt.encrypt(str));
  }

  /**
  * rsa解密
  * @privateKey 由于私钥只保存在后端，此方法只用于前端临时测试
  */
  static rsaDecrypt(str: string, privateKey) {
    if (!this.jsEncrypt) {
      this.jsEncrypt = new JsEncrypt({});
    }
    this.jsEncrypt.setPrivateKey(privateKey);
    return this.jsEncrypt.decrypt(window.atob(str));
  }

  /**
  * aes加密
  * @key 最好动态生成，由于是对称算法，加解密需要同样的key
  */
  static aesEncrypt(str: any, key: string) {
    if (typeof str !== 'string') {
      str = JSON.stringify(str);
    }
    const parseStr = CryptoJS.enc.Utf8.parse(str);
    const parseKey = CryptoJS.enc.Utf8.parse(key);
    const aesEncrypt = CryptoJS.AES.encrypt(parseStr, parseKey, {
      iv: parseKey,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.ZeroPadding
    }).toString();
    return aesEncrypt;
  }

  /**
  * aes解密
  * 本方法主要用于解密后端返回的加密数据，具体实现方法：
  * 前端动态生成key，把key和其他要传递的参数用rsa非对称算法加密传给后端，
  * 后端用私钥解密得到key，然后使用key调用aes对称加密数据，最后把数据传回前端，前端再调用aes解密方法得到数据
  */
  static aesDecrypt(str: string, key: string) {
    const parseKey = CryptoJS.enc.Utf8.parse(key);
    return CryptoJS.AES.decrypt(str, parseKey, {
      iv: parseKey,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);
  }


  /**
  * 字符串md5
  */
  static md5(str: string) {
    return CryptoJS.MD5(str).toString();
  }
  //获取服务器时间
  static getServiceTime(): number {
    var correcttime = 0;
    var _correcttime = "0"//this.getStorage("correcttime")
    if (_correcttime && _correcttime != "")
      correcttime = parseInt(_correcttime)
    return parseInt(new Date().getTime().toString()) + correcttime;
  }
  //如果值等于undefined返回“”
  static replace(key, value) {
    let type = typeof value;
    if (value === undefined) {
      return "";
    }
    else if (value === null) {
      return "";
    }
    if (type == "number") {
      return value + ""
    }
    return value;
  }
  //SHA1加密
  static buildSHA1(data: any, timestamp: string): string {
    try {
      var convdata = JSON.stringify(data, this.replace).replace(/\\/g, "").replace(/ /g, "+")
      if (data.objectName || data.Url) {
        convdata = convdata.replace(/%/g, "%25");
        convdata = convdata.replace(/:/g, "%3a");
        convdata = convdata.replace(/\//g, "%2f");
        convdata = convdata.replace('?', "%3f");
        convdata = convdata.replace(/=/g, "%3d");
        convdata = convdata.replace(/&/g, "%26");
        convdata = convdata.replace(/{/g, "%7b");
        convdata = convdata.replace(/}/g, "%7d");
        convdata = convdata.replace(/"/g, "%22");
        convdata = convdata.replace(/,/g, "%2c");
        convdata = convdata.toLocaleUpperCase();
        console.log(convdata);
      }

      var aesstr = this.aesEncrypt(convdata, environment.aeskey);
      //console.log(PublicJs.aeskey + "|ParamToAES:" + aesstr + " data:" + convdata);
      var arrTmp: string[] = [aesstr, environment.sha1key, timestamp];

      return arrTmp.join("")
    } catch (e) {
      //console.log(e)
    }
  }
  //生成签名
  static buildAutograph(data: any = null, backtype: string = "url"): string {
    // if (PublicJs.aeskey == "" || PublicJs.aeskey == "")//无密钥的玩去
    // {
    //     this.presentToast("非法路径访问");
    //     return
    // }    
    var tmpStr = "";//签名
    var timestamp: string = this.getServiceTime().toString();

    //console.log(JSON.stringify(data, this.replace));
    tmpStr = this.buildSHA1(data, timestamp)

    if (backtype == "json") {
      if (data.length > 0) {
        data[0].timestamp = timestamp
        data[0].signature = CryptoJS.SHA1(tmpStr).toString(CryptoJS.enc.Hex)
        data[0].appsource = environment.appsource
        data[0].appver = GlobalData.appver
        data[0].clientid = environment.clientid ? environment.clientid : ""
        data[0].nativeversion = environment.nativeversion ? environment.nativeversion : "";
      }
      else {
        data.timestamp = timestamp
        data.signature = CryptoJS.SHA1(tmpStr).toString(CryptoJS.enc.Hex)
        data.appsource = environment.appsource
        data.appver = GlobalData.appver
        data.clientid = environment.clientid ? environment.clientid : ""
        data.nativeversion = environment.nativeversion ? environment.nativeversion : "";
      }

      return JSON.stringify(data, this.replace);
    }
    else {

      var result = "timestamp=" + timestamp +
        "&signature=" + CryptoJS.SHA1(tmpStr).toString(CryptoJS.enc.Hex) +
        "&appsource=" + environment.appsource +
        "&appver=" + GlobalData.appver +
        "&clientid=" + (environment.clientid ? environment.clientid : "") +
        "&nativeversion=" + (environment.nativeversion ? environment.nativeversion : "") + "&"

      if (GlobalData.IsRemoteAssistance)
        result += "sysck=" + this.aesEncrypt(environment.aeskey + "|" + environment.sha1key, "asdlkjhqwekjhks@skdjhasd").replace("+", "%2B") + "&"

      for (let key in data) {
        if (data[key] == undefined || data[key] == null)
          data[key] = "";
        result += key + '=' + data[key] + '&';
      }
      result = result.slice(0, result.length - 1);

      return result;
    }

  }
  //生成Guid
  newguid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  static toGm() {
    var newkey = this.md5("0965khC@J1riehAA" + this.getDate("yyyyMMdd").replace(/-/g, "")).toUpperCase();
    return newkey.substring(0, "0965khC@J1riehAA".length)
  }

  static getDate(format: string): any {
    var year = ""
    var month = ""
    if (format.indexOf('yyyy') == -1) {
      month = format.substring(2, 3)
    }
    else {
      year = format.substring(4, 5)
      month = format.substring(7, 8)
    }
    var d = new Date();
    switch (format) {
      case "yyyy" + year + "MM" + month + "dd":
        return d.getFullYear() + year + this.getDate("MM" + month + "dd");
      case "yyyy" + year + "MM" + month + "dd hh:mm:ss":
        return this.getDate("yyyy-MM-dd") + " " + this.GenerateID(d.getHours()) + ":" + this.GenerateID(d.getMinutes()) + ":" + this.GenerateID(d.getSeconds());
      case "MM" + month + "dd":
        return this.GenerateID((d.getMonth() + 1)) + month + this.GenerateID(d.getDate());
      case "hh:mm:ss":
        return this.GenerateID(d.getHours()) + ":" + this.GenerateID(d.getMinutes()) + ":" + this.GenerateID(d.getSeconds());
      case "hh:mm":
        return this.GenerateID(d.getHours()) + ":" + this.GenerateID(d.getMinutes());
      default:
        return this.getDate("yyyy-MM-dd");
    }
  }

  static GenerateID(str: any) {
    var pad = "00"
    return pad.substring(0, pad.length - (str + "").length) + str
  }

  static getDAesString(data, key, iv) {//解密
    var key = CryptoJS.enc.Latin1.parse(key);
    var iv = CryptoJS.enc.Latin1.parse(iv);
    var decrypted = CryptoJS.AES.decrypt(data, key,
      {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.ZeroPadding
      });
    return decrypted.toString(CryptoJS.enc.Utf8)
  }

  static base64ToUint8Array(base64String) {
    let padding = '='.repeat((4 - base64String.length % 4) % 4);
    let base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');

    let rawData = window.atob(base64);
    let outputArray = new Uint8Array(rawData.length);

    for (var i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }
}


/*
*
const user = {
  'username': 'staff',
  'realname': '测试',
  'email': 'staff@123.com',
  'password': '123456'
};
// 前端加密数据到后端
this.http.post('http://192.168.2.10:9898/api/v1/public/req_rsa', Encrypt.rsaEncrypt(user)).subscribe(res => {
  console.log('test1:', res);
  debugger;
});

// const key = Utils.uuid().substr(0, 16); // 动态生成16位长度的key
const key = 'fa9353c6179dfbfa';
user.secretKey = key;
// 后端加密数据传递到前端，前端先把key加密传给后端
this.http.post('http://192.168.2.10:9898/api/v1/public/req_rsa_resp_aes', Encrypt.rsaEncrypt(user)).subscribe(res => {
  const result = Encrypt.aesDecrypt(res, key);
  console.log('test2:', result);
  debugger;
});
* */
