import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchSelectComponent } from './search-select.component';



@NgModule({
  declarations: [SearchSelectComponent],
  imports: [
    CommonModule
  ],
  exports:[SearchSelectComponent]
})
export class SearchSelectModule { }
