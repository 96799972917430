import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CallServiceComponent } from './call-service.component';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SearchSelectModule } from '../search-select/search-select.module';
import { SelectDateModule } from "src/app/module/select-date/select-date.module"



@NgModule({
  declarations: [CallServiceComponent],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SearchSelectModule,
    SelectDateModule,
  ],
  exports: [CallServiceComponent],
})
export class CallServiceModule {}
