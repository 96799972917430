import { Injectable } from '@angular/core'
import { NavController } from '@ionic/angular';
import { Utils } from '../providers/Utils';
import { Router, ActivatedRoute, Params } from '@angular/router'
import { GlobalData } from '../providers/GlobalData';
import { Encrypt } from '../providers/Encrypt';
import * as $ from "jquery";
import { Observable } from 'rxjs';
@Injectable()

export class BasePage {
    pagemd5: any;
    constructor(
        public utils: Utils,
        public nav: NavController,
        public activeRoute: ActivatedRoute) {
    }
    data: any;
    _params() {

    }
    _ngOnInit() {
    }
    _ionViewWillEnter() { }
    ngOnInit() {
        
         
        this.utils.syncData().then(() => {
            this._params();
            this._ngOnInit()
        })
    }

    ionViewWillEnter() {
        this.utils.syncData().then(() => {
            this._ionViewWillEnter()
        })
    }


    //===================

    push(page: any, params?: any): Promise<any> {
        //GlobalData.paramDate = params
         return this.nav.navigateForward(page, { queryParams: params });
    }
    pop(page:any,params?:any):Promise<any>{
          return this.nav.navigateBack(page, { queryParams: params });
    }
   
    get(item?:any)
    {  
        return  Observable.create(observer => {
        this.activeRoute.queryParams.subscribe((params: Params)  => {
            if(item){
                observer.next(params[item]); // data是主api约定返回的数据
            }else{
                observer.next(params);
            }
        }, err => {
            observer.error(err);
        });
    });
        // return GlobalData.paramDate["queryParams"][item]
    }
    //唤起app刷新密钥用
    secuKeyListennerCallBack(callback) {
        // console.log("secuKeyListennerCallBack============" + callback);
        // PublicJs.isUpdate = false;
        this.utils.syncData();
        //调用原生，再次设置监听
        addSecuKeyListenner((callback) => this.secuKeyListennerCallBack(callback), function (error) { });
    }
    
}